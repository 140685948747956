import {
    ChangeDetectorRef,
    Component,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import Speech from 'speak-tts';
import { FormControlNameEnums } from 'app/shared/shared-dto';
import { catchError, of, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InventoryCampService } from '../../Campaign/inventory/inventory-camp.service';
import { TrackingNumberService } from 'app/shared/shared-Services/tracking-number.service';
import { Contact, Country } from './country.type';
import { MatSelect } from '@angular/material/select';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

interface AudioFile {
    id: number;
    groupId: number;
    file_key: string;
    status: string;
    filename: string;
    url: string;
}
@Component({
    selector: 'app-create-new-tracking-number',
    templateUrl: './create-new-tracking-number.component.html',
    styleUrls: ['./create-new-tracking-number.component.scss'],

})
export class CreateNewTrackingNumberComponent implements OnInit {
    isCloudUser: boolean = false;
    yearlyBilling: boolean = true;

    poolNumberForm: FormGroup;
    singleNumberForm: FormGroup;
    createSingleForm: FormGroup;
    roundRobinForm: FormGroup;
    simaltaneousForm: FormGroup;
    createRoundNumber: FormGroup;
    createSimalNumber: FormGroup;
    ivrFormArray: UntypedFormGroup;
    AllOwners: any;
    AllSources: any;
    savedDataSingleNumber: any;
    speechTextWhisper: string;
    speechTextWhisper2: string;
    choosePromptReplacer: string;
    fileUrl: string;
    TrackingNumberList: any;
    AllCampaignList: any;
    //routing options
    Single_Phone: boolean = true;
    IVR: boolean = false;
    Round_Robin: boolean = false;
    Simultaneous: boolean = false;

    //Input file function variables
    deleteKey: string;
    deleteResponse: any;
    responseUrl: any;
    APiFile: any;
    fileNameSingleCall: string;
    response: any;
    closeDialog: boolean = false;
    fullFile: any;
    fileNameRoundCall: any;
    fileNameSimalCall: any;

    //speak tts
    speech: any;
    speechData: any;
    togglePlay: boolean;
    html: string;
    result: any;
    isPlayAudio = true;
    isPlayAudioPrompt=true;
    cursorDrop: boolean = false;
    objADD = {};
    IVRSettingsArray: any = [];
    routeobj = {};
    campaignId: number;
    tnSuggestedNumber = [];
    showError: boolean;

    telOptions = {
        initialCountry: 'us',
        preferredCountries: ['us', 'pk'],
        autoHideDialCode: false,
        separateDialCode: false,

        formatOnDisplay: false,
        nationalMode: false,
    };

    color = [];
    checkToggleBeforeCall: boolean;
    fileNameBeforeCall: any;
    savedData: any;
    savedSimalData: any;
    sub = new Subject();
    showEdit: boolean = false;
    UpdateResponse: any;
    trackingId: number;
    campId: number;
    isSubmit: boolean = true;
    closeModal: string;
    readOnly: boolean = false;
    isDisabled: boolean = false;
    singleNumber: boolean = true;
    campaignres: any;
    campaignList: any;
    countries: Country[];
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    @ViewChild('select') select: MatSelect;
    sampleForm: FormGroup;
    code = '+1';
    simCode = '+1';
    proImage: any;

    contact: Contact;
    contacts: Contact[];
    countriesList: any;
    audioPlaying: boolean = false;
    selectedCountryCode = 'us';
    phoneCode = '1';
    countryCodes = [
        'us',
        'ca',
        'de',
        'mx',
        'br',
        'pt',
        'cn',
        'be',
        'jp',
        'ph',
        'lu',
        'bs',
    ];

    selectedPropertiesControl = new FormControl();
    formArray: FormArray;
    selectedOption: string = 'singlePnone';
    groupId = localStorage.getItem('Group');
    selectedAudioKey: string = '';
    selectedIvrPromptFile: string = '';
    ivr_prompt_id:number=null;

    displayedNumbers: any[] = [];
  currentIndex = 0;
  pageSize = 50; // Adjust this based on your expected list size
  isLoading = false;

  @ViewChild(CdkVirtualScrollViewport) virtualScroll!: CdkVirtualScrollViewport;

  private scrollSubscription: Subscription | undefined;
    constructor(
        private _campaignService: InventoryCampService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: FormBuilder,
        private _router: Router,
        public dialog: MatDialog,
        private modalService: NgbModal,
        private tnNumberService: TrackingNumberService,
        private route: ActivatedRoute
    ) {
        //tracking Number Form
        this.singleNumberForm = this._formBuilder.group({
            campaignId: [''],
            numberStatus: [''],
            areacode: ['', Validators.required],
            spamguard: [true],
            recordCall: [true],
            phoneNumbers: [''],
            country: ['us'],
            countryCode: ['+1'],
            siNumber: [''],
            sms: [true],
            tnNumber: [''],
            number: ['', Validators.required],
            numberName:['', [Validators.required, Validators.maxLength(30)]],
            voicemail: Boolean,
            ringToNumber: [''],
            overflowNumbers: [''],
            CallerID: ['Caller ID'],
            numberType: [''],
            IVRRoutingType: [''],
            trackingStatus: [true],
        });

        // Get the country telephone codes
        this.tnNumberService.countries$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((codes: Country[]) => {
                this.countries = codes;
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        //SingleNumber Form
        this.createSingleForm = this._formBuilder.group({
            phonenumber: ['', [Validators.required, Validators.minLength(14)]],
            country: ['us'],
            countryCode: ['+1'],
            playWhisperBeforeCallSingle: [false],
            playWhisperBeforeCallTextSingle: [''],
            singleVoice: [Boolean],
            singleCallAudio: [''],
            singleCallUrl: [''],
            singleCallKey: [''],
        });

        //IVR MAIN formARRAY
        this.ivrFormArray = this._formBuilder.group({
            overFlowSection: this._formBuilder.array([this.createIvrArray()]),
        });
    }

    getSelectedProperties(): any[] {
        const selectedProperties = {};
        this.selectedPropertiesControl.value.forEach((property) => {
            selectedProperties[property] = true;
        });
        return this.formArray.controls.map((formGroup) => {
            const properties = {};
            Object.keys(formGroup.value).forEach((key) => {
                if (selectedProperties[key]) {
                    properties[key] = formGroup.value[key];
                }
            });
            return properties;
        });
    }

    ngOnInit(): void {
        this.fetchAudioFiles();
        this.getAllOwners();
        this.getDataForUpdate();


        this.speech = new Speech(); // will throw an exception if not browser supported
        if (this.speech.hasBrowserSupport()) {
            // returns a boolean
            this.speech
                .init({
                    volume: 1,
                    lang: 'en-GB',
                    rate: 1,
                    pitch: 1,
                    voice: 'Google UK English Male',
                    splitSentences: true,
                    listeners: {
                        onvoiceschanged: (voices) => {},
                    },
                })
                .then((data) => {
                    this.speechData = data;
                    data.voices.forEach((voice) => {});
                })
                .catch((e) => {
                    // console.error('An error occured while initializing : ', e);
                });
        }
    }

    audioFiles: any[] = [];

    fetchAudioFiles() {
        this._campaignService.getAudioFileList().subscribe(
            (files: AudioFile[]) => {
                this.audioFiles = files;
            },
            (error) => {
                console.error('Error fetching audio files', error);
            }
        );
    }
    toggleAudio(audioPlayer: HTMLAudioElement): void {
        if (audioPlayer.paused) {
            audioPlayer.play();
            this.audioPlaying = true;  // Audio is playing
        } else {
            audioPlayer.pause();
            this.audioPlaying = false;  // Audio is paused
        }

        // Listen for the audio ended event to reset the icon
        audioPlayer.onended = () => {
            this.audioPlaying = false;  // Reset icon to play when audio finishes
        };
    }

    uniqueValuesValidator(): ValidatorFn {
        return (formGroup: FormGroup): ValidationErrors | null => {
            const values = Object.values(formGroup.value);
            const unique = new Set(values);
            if (unique.size === values.length) {
                return null;
            } else {
                return { uniqueValues: true };
            }
        };
    }

    changeSelectedCountryCode(value: string): void {
        this.selectedCountryCode = value;
        this.phoneCode =
            this.tnNumberService.findCountryCodeByTwoLetterAbbreviation(
                this.selectedCountryCode
            );
    }

    /**
     * Get country info by iso code
     *
     * @param iso
     */
    getCountryByIso(iso: string): Country {
        return this.countries.find((country) => country.iso === iso) || null;
    }

    getcountry(e, i) {
        this.code = e.code;
        this.simCode = e.code;
        this.proImage = e.flagImagePos;
    }

    checkValiditySingle() {
        if (this.createSingleForm.value.playWhisperBeforeCallSingle == true) {
            if (
                this.createSingleForm.value.playWhisperBeforeCallTextSingle ==
                    '' &&
                this.createSingleForm.value.singleCallKey == ''
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    checkValidityIVR() {
        if (
            this.ivrFormArray.value.overFlowSection[0].playWhisperBeforeCall ==
            true
        ) {
            if (
                this.ivrFormArray.value.overFlowSection[0]
                    .playWhisperBeforeCallText == '' &&
                this.ivrFormArray.value.overFlowSection[0].BeforeCallKey == ''
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    getDataForUpdate() {
        this.route.queryParams.subscribe(params => {
            const id = params['id'];  // for query parameters
            if(id){
                this._campaignService.getSingleTrackingData(id).subscribe(res => {
                    if(res.status){
                        this.showEdit = true;
                        this.readOnly = true;
                        this.isDisabled = true;
                        this.patchUpdatedValues(res?.data);
                    }
                    console.log(res,'tracking data');
                });
            }
          });
        // this._campaignService.updateNewTrackingNnumberData
        //     .pipe(takeUntil(this.sub))
        //     .subscribe((data) => {
        //         if (data) {
        //             this.showEdit = true;
        //             this.readOnly = true;
        //             this.isDisabled = true;
        //             this.patchUpdatedValues(data);
        //         }
        //     });
    }


    // Helper function to sanitize phone numbers
        private sanitizePhoneNumber(phoneNumber: string): string {
                 return phoneNumber.replace(/\D/g, ''); // Removes any non-numeric characters
                }

    patchUpdatedValues(obj) {
        this.UpdateResponse = obj;
        this.trackingId = this.UpdateResponse?.id;
        this.campId = this.UpdateResponse?.campaignId;
        if (this.UpdateResponse?.trackingStatus == 'Inactive') {
            var trackStatus = false;
        } else {
            trackStatus = true;
        }
        if (this.UpdateResponse?.recordCall == 1) {
            var recCallBool = true;
        } else {
            recCallBool = false;
        }

        if (this.UpdateResponse?.numberType === 'Single') {
            this.yearlyBilling = true;
            this.singleNumber = true;
            // this.singleNumberFormValue.clear()
            this.singleNumberForm.patchValue({
                campaignId: this.UpdateResponse?.campaignId,
                areacode: this.UpdateResponse?.areaCode,
                number: this.UpdateResponse?.number,
                numberName: this.UpdateResponse?.name,
                adSource: this.UpdateResponse?.adSource,
                CallerID: this.UpdateResponse?.callerIdType,
                trackingStatus: trackStatus,
                // singleNumber: this.UpdateResponse?.ringToNumber,
                recordCall: recCallBool,
            });
        }
        else if (this.UpdateResponse?.numberType === 'Pool') {
            this.yearlyBilling = true;
            this.singleNumber = false;
            // this.singleNumberFormValue.clear()
            if (this.UpdateResponse?.trackingStatus == 'Inactive') {
                var trackStatus = false;
            } else {
                trackStatus = true;
            }
            if (this.UpdateResponse?.recordCall == 1) {
                var recCallBool = true;
            } else {
                recCallBool = false;
            }

            this.singleNumberForm.patchValue({
                campaignId: this.UpdateResponse?.campaignId,
                areacode: this.UpdateResponse?.areaCode,
                number: this.UpdateResponse?.number,
                numberName: this.UpdateResponse?.name,
                adSource: this.UpdateResponse?.adSource,
                CallerID: this.UpdateResponse?.callerIdType,
                trackingStatus: trackStatus,

                recordCall: recCallBool,
            });
        }

        //Single Phone Number

        if (this.UpdateResponse?.routingType === 1) {
            this.Round_Robin = false;
            this.Simultaneous = false;
            this.Single_Phone = true;
            this.IVR = false;

            // this.createSingleForm.reset();
            // this.ivrFormArray.reset();
            this.ivrArray.clear();
            this.addIvrArray();
            // this.ivrArray.reset();

            if (this.UpdateResponse?.whisperFile) {
                //payload for sending Recording Key
                var keyFile = {
                    objectKey: this.UpdateResponse?.whisperFile,
                };
                this.createSingleForm.controls.singleCallKey.setValue(
                    this.UpdateResponse?.whisperFile
                );

                this._campaignService.getAudioUrl(keyFile).subscribe((res) => {

                    var respSingle: any = res;

                    var singleResponse = respSingle?.body?.URL;
                    this.createSingleForm.controls.singleCallUrl.setValue(
                        singleResponse
                    );
                });
                if (this.UpdateResponse?.isWhisper == 1) {
                    this.UpdateResponse?.isWhisper == true;
                } else {
                    this.UpdateResponse?.isWhisper == false;
                }
                this.createSingleForm.patchValue({
                    country: this.UpdateResponse.country,
                    countryCode: this.UpdateResponse.countryCode,
                    phonenumber: this.UpdateResponse?.ringToNumber,
                    playWhisperBeforeCallSingle: this.UpdateResponse?.isWhisper,
                    playWhisperBeforeCallTextSingle:
                        this.UpdateResponse.whisperFile,
                });
                this.fileNameSingleCall = this.UpdateResponse.whisperFile;
            } else if (this.UpdateResponse?.globalWhisperText) {
                if (this.UpdateResponse?.isWhisper == 1) {
                    this.UpdateResponse?.isWhisper == true;
                } else {
                    this.UpdateResponse?.isWhisper == false;
                }
                this.createSingleForm.patchValue({
                    country: this.UpdateResponse.country,
                    countryCode: this.UpdateResponse.countryCode,
                    phonenumber: this.UpdateResponse?.ringToNumber,
                    playWhisperBeforeCallSingle: this.UpdateResponse?.isWhisper,
                    playWhisperBeforeCallTextSingle:
                        this.UpdateResponse?.globalWhisperText,
                });
            } else {
                if (this.UpdateResponse?.isWhisper == 1) {
                    this.UpdateResponse?.isWhisper == true;
                } else {
                    this.UpdateResponse?.isWhisper == false;
                }
                this.createSingleForm.patchValue({
                    country: this.UpdateResponse.country,
                    countryCode: this.UpdateResponse.countryCode,
                    phonenumber: this.UpdateResponse?.ringToNumber,
                    playWhisperBeforeCallSingle: this.UpdateResponse?.isWhisper,
                    playWhisperBeforeCallTextSingle:
                        this.UpdateResponse?.globalWhisperText,
                });
            }

            this.createSingleForm.patchValue({
                RoundVoice: this.UpdateResponse?.voicemail,
            });
        }
        //IVR
        else if (this.UpdateResponse?.routingType === 4) {
            this.mapIVRSettings(this.UpdateResponse);

                 this.speechTextWhisper=this.UpdateResponse.globalWhisperText;
                 this.selectedIvrPromptFile=this.UpdateResponse.whisperFile;
                this.ivr_prompt_id=this.UpdateResponse?.ivr_prompt_id;
                this.choosePromptReplacer=this.UpdateResponse.whisperFile;
                this.fileUrl=this.UpdateResponse.url;



          }

    }

    async getSuggesttnNumbers(e: string) {
        this.tnSuggestedNumber = [];
        this.displayedNumbers = [];
        if (e.length > 2) {
          let areaCode = { areaCode: e };
          const response: any = await this.tnNumberService.getTnNumbers(areaCode).toPromise();  // Use async/await or promise
          console.log("Response is:", response)
          if (response.status === true) {
            this.tnSuggestedNumber = response.body.data;
            this.displayedNumbers = this.tnSuggestedNumber.slice(0, this.pageSize);
            this.currentIndex = this.pageSize;
            this.showError = false;
            if (response.body.data.length === 0) {
              this.showError = true;
            }
            this._changeDetectorRef.detectChanges(); // Trigger change detection
          }
        } else {
          // Handle empty area code case (optional)
        }
        console.log("Displayed numbers:", this.displayedNumbers);
      }

      loadMoreItems() {
        if (this.isLoading || this.currentIndex >= this.tnSuggestedNumber.length) {
          return;
        }

        this.isLoading = true;

        const nextBatch = this.tnSuggestedNumber.slice(this.currentIndex, this.currentIndex + this.pageSize);
        this.displayedNumbers = [...this.displayedNumbers, ...nextBatch];
        this.currentIndex += this.pageSize;

        this.isLoading = false;
      }

      ngAfterViewInit() {
        this.virtualScroll.elementScrolled().subscribe(() => {
          this.loadMoreItems();
        });
      }
    keyPressNumeric(event) {

        var inp = String.fromCharCode(event.keyCode);

        if (/[0-9+]/.test(inp)) {
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }

    /**
     * get all owners email
     */
    getAllOwners() {
        this._campaignService.getAllOwners().subscribe((responseObj: any) => {
            this.AllOwners = responseObj.body;
        });
        // this._campaignService.getAllSources().subscribe((responseObj: any) => {
        //     this.AllSources = responseObj.body.payload;
        // });
    }

    trackingList() {
        var Page = 1;
        var Row = 30;

        this._campaignService
            .getAllCampaignList(Page, Row)
            .subscribe((responseObj: any) => {
                if (responseObj?.status === true) {
                    this._campaignService.SubVariable.next(responseObj);

                    this.AllCampaignList = responseObj.body.data;
                    // this.TrackingNumberList = responseObj.body.tn;
                }
            });
    }

    get ivrArray() {
        return this.ivrFormArray.get('overFlowSection') as FormArray;
    }
    addIvrArray() {
        this.ivrArray.push(this.createIvrArray());
    }
    removeIvrArray(i) {
        this.ivrArray.removeAt(i);
    }

    changeSim(e) {
        // console.log(this.simaltaneousForm);
        // console.log(this.singleNumberForm);
        // console.log(e)
    }

    createIvrArray() {
        return this._formBuilder.group({
            country: ['us'],
            countryCode: ['+1'],
            keyPress: ['', RxwebValidators.unique()],
            IVRAction: ['Forwarding to a phone number', Validators.required],
            playWhisperBeforeCall: [true],
            playWhisperBeforeCallText: '',
            BeforeCallAudio: '',
            BeforeCallUrl: '',
            BeforeCallKey: '',
            playWhisperForFirstTimeCaller: [true],
            playWhisperForFirstTimeCallerText: '',
            FirstTimeCallerAudio: '',
            FirstTimeCallerUrl: '',
            FirstTimeCallerKey: '',
            voicePromptOnSelection: [true],
            voicePromptOnSelectionText: '',
            voicePromptOnSelectionAudio: '',
            voicePromptOnSelectionUrl: '',
            voicePromptOnSelectionKey: '',
            IVRRoutingType: [1],
            IVRRingToNumber: [
                '',
                [Validators.required, Validators.minLength(14)],
            ],
            IVROverflowNumbers: this._formBuilder.array([]),
        });
    }

    dialCodeSingle = '+1';
    dialCodeSimal = '+1';
    dialCodeRobin = '+1';
    /**
     * on changing country dial code would change
     * @param event country dialCode
     */
    onCountryChange(event, i) {
        if (this.Single_Phone == true) {
            this.dialCodeSingle = event.code;

            this.createSingleForm
                .get('countryCode')
                .setValue(this.dialCodeSingle);
        } else if (this.Round_Robin) {
            this.dialCodeRobin = event.code;

            this.roundRobinForm
                .get(FormControlNameEnums.overflowNumbers)
                ['controls'][i].controls['countryCode'].setValue(
                    this.dialCodeRobin
                );
        } else if (this.Simultaneous) {
            this.dialCodeSimal = event.code;
            this.simaltaneousForm
                .get(FormControlNameEnums.overflowNumbers)
                ['controls'][i].controls['countryCode'].setValue(
                    this.dialCodeSimal
                );
        }
    }

    dialCodeIvrSingle = '+1';
    dialCodeIvrRobin = '+1';
    dialCodeIvrSimal = '+1';
    codeIvr = '+1';

    onCountryChangeIVR(event, i, r) {
        this.codeIvr;
        if (this.ivrFormArray.value.overFlowSection[i].IVRRoutingType === 1) {
            this.codeIvr = event.code;

            this.ivrFormArray
                .get('overFlowSection')
                ['controls'][i].controls.countryCode.setValue(this.codeIvr);
        } else if (
            this.ivrFormArray.value.overFlowSection[i].IVRRoutingType === 2
        ) {
            this.codeIvr = event.code;
            this.ivrFormArray
                .get('overFlowSection')
                ['controls'][i].controls.IVROverflowNumbers.controls[
                    r
                ].controls.countryCode.setValue(this.codeIvr);
        } else if (
            this.ivrFormArray.value.overFlowSection[i].IVRRoutingType === 3
        ) {
            this.codeIvr = event.code;
            this.ivrFormArray
                .get('overFlowSection')
                ['controls'][i].controls.IVROverflowNumbers.controls[
                    r
                ].controls.countryCode.setValue(this.codeIvr);
        }
    }

    /**
     * disable if the condition not match
     * @param i index of first form Array
     * @param r index of second form array
     */

    //// Save the text to play as audio
    whisperFunction(event) {
        this.speechTextWhisper = event;
    }

    //Open Modal Service
    openDialogWithTemplateRef(templateRef: TemplateRef<any>, i) {
        this.dialog.open(templateRef);
    }

    ////Input File Delete & save Functions///////////////

    ///Single Phone Numberrr///////////////
    /**
     * Input file Function
     * @param $event File
     * @param i index
     */
    InputChange($event, i) {
        if (this.Single_Phone) {
            this.APiFile = $event.target.files[0];

            if (this.createSingleForm.value.singleCallAudio == null) {
                this.createSingleForm.controls.singleCallAudio.setValue(
                    this.APiFile.name
                );
            }

            if (this.createSingleForm.value.singleCallAudio.includes('\\')) {
                this.fileNameSingleCall =
                    this.createSingleForm.value.singleCallAudio.split('\\');
                this.fileNameSingleCall = this.fileNameSingleCall[2];
            } else {
                this.fileNameSingleCall =
                    this.createSingleForm.value.singleCallAudio;
            }

            var formData = new FormData();
            formData.append('file', this.APiFile);

            this._campaignService.postAudio(formData).subscribe((res) => {
                this.response = res;
                this.responseUrl = this.response?.body.url;

                this.deleteKey = this.response?.body?.key;

                //for audio URL
                this.createSingleForm.controls.singleCallUrl.setValue(
                    this.responseUrl
                );

                //for Audio Key
                this.createSingleForm.controls.singleCallKey.setValue(
                    this.deleteKey
                );
            });
        }
    }

    /**
     * delete function
     */
    deleteFileButton() {
        if (this.Single_Phone) {
            this.createSingleForm.controls.singleCallUrl.setValue('');

            //for Audio Key
            this.createSingleForm.controls.singleCallKey.setValue('');

            this.createSingleForm.controls.singleCallAudio.reset();
            if (this.createSingleForm.value.singleCallUrl == '') {
                this.createSingleForm.controls.playWhisperBeforeCallTextSingle.setValue(
                    ''
                );
                this.createSingleForm.controls.playWhisperBeforeCallTextSingle.enable();
            }

            this._campaignService
                .deleteAudioFile(this.deleteKey)
                .subscribe((res) => {
                    this.deleteResponse = res;
                    if (
                        this.deleteResponse?.body?.message ==
                        'Audio deleted successfully!'
                    ) {
                        this.deleteKey = '';
                    }
                });
            this.createSingleForm.controls.playWhisperBeforeCallTextSingle.enable();

            this.responseUrl = '';
        }
    }

    /**
     * Input Change Ivr Button
     * @param $event
     * @param i
     */

    InputChangeIVR($event, i) {
        this.APiFile = $event.target.files[0];

        // Update the form value immediately
        let fileName = this.APiFile ? this.APiFile.name : '';
        // this.choosePromptReplacer = fileName;
        this.ivrFormArray.get('overFlowSection')['controls'][i].controls.BeforeCallAudio.setValue(fileName);

        // If you want to extract the file name
        // if (fileName.includes('\\')) {
        //     this.fileNameBeforeCall = fileName.split('\\').pop();
        // } else {
        //     this.fileNameBeforeCall = fileName;
        // }

        // Prepare FormData and make the API call
        const formData = new FormData();
        formData.append('files', this.APiFile);

        this._campaignService.postAudio(formData).subscribe((res) => {
            // this.response = res;
            // this.responseUrl = this.response.body.url;
            // this.deleteKey = this.response.body.key;

            // // Update URL and Key in the form
            // this.ivrFormArray
            //     .get('overFlowSection')
            //     ['controls'][i].controls.BeforeCallUrl.setValue(
            //         this.responseUrl
            //     );
            // this.ivrFormArray
            //     .get('overFlowSection')
            //     ['controls'][i].controls.BeforeCallKey.setValue(this.deleteKey);
            this.fetchAudioFiles();
        });

    }

    deleteFile(audio: AudioFile, index: number) {
        // Implement delete functionality here
        // console.log('Deleting file:', audio.filename);
        this.audioFiles.splice(index, 1);
    }

    /**
     * ivr delete button
     * @param items
     * @param i
     */

    selectAudio(audio: any) {
        this.choosePromptReplacer = audio.filename; // Update the chosen prompt
        this.closeDialog = true; // Close the dialog
        this.selectedAudioKey = audio.file_key;
        this.ivr_prompt_id= audio?.id;
        this.fileUrl= audio.url;
        this.selectedIvrPromptFile=audio.filename;
    }



    deleteFileButtonIVR(items, i) {
        this.ivrFormArray
            .get('overFlowSection')
            ['controls'][i].controls.BeforeCallUrl.setValue('');

        //for Audio Key
        this.ivrFormArray
            .get('overFlowSection')
            ['controls'][i].controls.BeforeCallKey.setValue('');

        this.ivrFormArray
            .get('overFlowSection')
            ['controls'][i].controls.BeforeCallAudio.reset();
        if (this.ivrFormArray.value.overFlowSection[i].BeforeCallUrl == '') {
            this.ivrFormArray
                .get('overFlowSection')
                ['controls'][i].controls.playWhisperBeforeCallText.setValue('');
            this.ivrFormArray
                .get('overFlowSection')
                ['controls'][i].controls.playWhisperBeforeCallText.enable();
        }

        this._campaignService
            .deleteAudioFile(this.deleteKey)
            .subscribe((res) => {
                this.deleteResponse = res;
                if (
                    this.deleteResponse.body.message ==
                    'Audio deleted successfully!'
                ) {
                    this.deleteKey = '';
                }
            });
        this.ivrFormArray
            .get('overFlowSection')
            ['controls'][i].controls.playWhisperBeforeCallText.enable();
        this.responseUrl = '';
    }

    PlayTextToSpeech() {
        if (this.Single_Phone) {
            this.togglePlay = true;
            this.isPlayAudio = !this.isPlayAudio;
            this.html =
                this.createSingleForm.value.playWhisperBeforeCallTextSingle;

            var temporalDivElement = document.createElement('div');
            // Set the HTML content with the providen
            temporalDivElement.innerHTML = this.html;
            // Retrieve the text property of the element (cross-browser support)
            this.result =
                temporalDivElement.textContent ||
                temporalDivElement.innerText ||
                '';
            this.speech
                .speak({
                    text: this.result,
                })
                .then(() => {})
                .catch((e) => {});
        }
    }

    /////////IVR////////////

    PlayTextToSpeechIVR(i) {
        this.togglePlay = false;
        this.isPlayAudioPrompt = true;
        this.html = this.ivrFormArray.value.overFlowSection[i].playWhisperBeforeCallText;

        var temporalDivElement = document.createElement('div');
        // Set the HTML content with the providen
        temporalDivElement.innerHTML = this.html;
        // Retrieve the text property of the element (cross-browser support)
        this.result =
            temporalDivElement.textContent ||
            temporalDivElement.innerText ||
            '';
            if (this.isPlayAudio) {
                this.playSpeech(this.result);
            } else {
                this.pauseSpeech();
            }
            this.isPlayAudio = !this.isPlayAudio;

    }
    PlayTextToSpeechNew(text: string) {
        this.isPlayAudio = true;
        if (this.isPlayAudioPrompt) {
            this.playSpeech(text);
        } else {
            this.pauseSpeech();
        }
        // Toggle the isPlayAudioPrompt flag
        this.isPlayAudioPrompt = !this.isPlayAudioPrompt;
    }
    playSpeech(text: string) {
        this.speech.speak({
            text,
            queue: false,
            listeners: {
                onend: () => {
                    // When speech ends, ensure the play/pause state is reset
                    this.isPlayAudio = true;
                    this.isPlayAudioPrompt = true;
                    console.log("Speech ended, toggling button state");
                }
            }
        }).then(() => {
            console.log("Speech started");
        }).catch((e: any) => {
            console.error("An error occurred:", e);
        });
    }
    pauseSpeech() {
        this.speech.cancel(); // Effectively acts as "pause"
        console.log("Speech paused");
    }
    /**
     * //color on each keypress
     * @param i Index of ivr
     * @returnsreturns true or false
     */
    //color on each keypress

    getColor(i) {
        this.color = [
            'RGBA(206,199,255,0.32)',
            'RGBA(255,204,255,0.32)',
            'RGBA(255,196,196,0.32)',
            'rgb(255 253 194 / 32%)',
            'rgb(217 255 209 / 32%)',
            'rgb(204 255 255 / 32%)',
            'rgb(255 218 196 / 32%)',
            'rgb(230 204 255 / 32%)',
            'rgb(219 252 248 / 32%)',
            'rgb(191 210 255 / 32%)',
        ];
        return this.color[i];
    }
    /**
     * ToggleCheck
     */
    toggleCheckPlayWhisperBeforeCall(event, i) {
        this.checkToggleBeforeCall = event.checked;
        if (this.checkToggleBeforeCall == false) {
            this.ivrFormArray.get('overFlowSection')['controls'][i].controls.playWhisperBeforeCallText.disable();
        } else {
            this.ivrFormArray
                .get('overFlowSection')['controls'][i].controls.playWhisperBeforeCallText.enable();
        }
    }


    /**
     * change Round Robing routing
     * @param event
     * @param i
     */

    onSave() {
        this.isSubmit = false;
        if (this.UpdateResponse?.id) {
            this.updateTrackingNumber();
        } else {
            this.saveTrackingNumber();
        }
    }
    datacode = [];
    roleString: any;
    codeing: any;
    tnCode(e) {
        this.codeing = e;
    }

    checkformValue(e) {
        // console.log(this.ivrFormArray, e)
        // console.log(e.get('IVRRingToNumber'))
    }

    /**
     * create New Tracking Number
     */
    saveTrackingNumber() {
        this.cursorDrop = true;

        // Prepare the number settings object based on billing type
        let numberSettings;
        if (this.yearlyBilling) {
            const trackingStatus = this.singleNumberForm.value.trackingStatus ? 1 : 0;
            const recordCall = this.singleNumberForm.value.recordCall ? 1 : 0;

            numberSettings = {
                areacode: this.singleNumberForm.value.areacode,
                number: this.singleNumberForm.value.number,
                numberType: 'Single',
                numberName: this.singleNumberForm.value.numberName,
                spamguard: 0,
                recordCall,
                sms: 0,
                trackingStatus,
                callerIdType: this.singleNumberForm.value.CallerID,
            };
            this.campId = this.singleNumberForm.value.campaignId;
        }
        else {
            const poolTrackingStatus = this.poolNumberForm.value.ad_active ? 1 : 0;
            const poolRecordCall = this.poolNumberForm.value.ad_recordCall ? 1  : 0;

            numberSettings = {
                areacode: this.poolNumberForm.value.areacode,
                myQuantityPool: this.poolNumberForm.value.quantity,
                number: '',
                numberType: 'Pool',
                numberName: this.poolNumberForm.value.tracking_number_name,
                adSource: this.poolNumberForm.value.ad_source,
                spamguard: 0,
                trackingStatus: poolTrackingStatus,
                recordCall: poolRecordCall,
                sms: 0,
                callerIdType: this.poolNumberForm.value.CallerPoolID,
            };
            this.campId = this.poolNumberForm.value.campaignId;
        }

        // Prepare the route settings object based on selected phone type
        let routeSettings;
        let voicemailEnabled = false;
        let ivr = 0;
        let IVRSettings = [];

        if (this.Single_Phone) {
            const singlePhoneVoice = !!this.createSingleForm.value.singleVoice;
            const singlePhoneWhisperToggle =
                !!this.createSingleForm.value.playWhisperBeforeCallSingle;

            const singleCallObject = {
                text:
                    this.createSingleForm.value
                        .playWhisperBeforeCallTextSingle || '',
                fileName: this.createSingleForm.value.singleCallKey || '',
            };
            // console.log(this.createSingleForm.value.phonenumber,'ringToNumber');
            routeSettings = {
                routingType: 1,
                voicemail: singlePhoneVoice ? 1 : 0,
                country: this.createSingleForm.value.country,
                countryCode: this.createSingleForm.value.countryCode,
                // ringToNumber: this.createSingleForm.value.phonenumber,
                    ringToNumber: this.sanitizePhoneNumber(this.createSingleForm.value.phonenumber), // Sanitized

                playWhisperBeforeCall: singlePhoneWhisperToggle,
                playWhisperBeforeCallText: singleCallObject,
            };
            voicemailEnabled = singlePhoneVoice;
        } else if (this.Round_Robin) {
            const roundVoice = this.createRoundNumber.value.RoundVoice ? 1 : 0;
            const roundPhoneWhisperToggle =
                !!this.createRoundNumber.value.playWhisperBeforeCallRound;

            const roundCallObject = {
                text:
                    this.createRoundNumber.value
                        .playWhisperBeforeCallTextRound || '',
                fileName: this.createRoundNumber.value.roundCallKey || '',
            };

            routeSettings = {
                routingType: 2,
                voicemail: roundVoice,
                playWhisperBeforeCall: roundPhoneWhisperToggle,
                playWhisperBeforeCallText: roundCallObject,
            };
        } else if (this.IVR) {
            ivr = 1;
            routeSettings = {
                routingType: 4,
            };

            IVRSettings = this.ivrFormArray.value.overFlowSection.map((x) => ({
                keyPress: x.keyPress,
                destination: x.destination,
                IVRRingToNumber: this.sanitizePhoneNumber(x.IVRRingToNumber),
                IVRAction: x.IVRAction,
                IVRRoutingType: x.IVRRoutingType,
                country: x.country,
                countryCode: x.countryCode,
                IVROverflowNumbers: x.IVROverflowNumbers || [],
                playWhisperBeforeCall: x.playWhisperBeforeCall ? true : false,
                playWhisperBeforeCallText: {
                    text: x.playWhisperBeforeCallText || '',
                    fileName:  '',
                },
            }));
        } else {
            const simalVoice = this.createSimalNumber.value.SimalVoice ? 1 : 0;
            const simalPhoneWhisperToggle =
                !!this.createSimalNumber.value.playWhisperBeforeCallSimal;

            const simalCallObject = {
                text:
                    this.createSimalNumber.value
                        .playWhisperBeforeCallTextSimal || '',
                fileName: this.createSimalNumber.value.simalCallKey || '',
            };

            routeSettings = {
                routingType: 3,
                voicemail: simalVoice,
                playWhisperBeforeCall: simalPhoneWhisperToggle,
                playWhisperBeforeCallText: simalCallObject,
            };
        }

        // Prepare payload
        const payload = {
            groupId: this.groupId,
            campaignId: this.campId,
            audioFile: this.selectedAudioKey, // Initialize empty
            ivr_prompt_id:this.ivr_prompt_id,
            voicemailEnabled: voicemailEnabled,
            numberSettings: numberSettings,
            routeSettings: routeSettings,
            ivr: ivr,
            IVRSettings: IVRSettings,
        };

        // Set audioFile based on routing type
        if (this.IVR) {
            const firstIVRSetting = IVRSettings[0]; // Get the first IVR setting

            if (firstIVRSetting && firstIVRSetting.playWhisperBeforeCallText) {
                payload.audioFile = this.selectedAudioKey || '';
            }
        }
        else if (this.Single_Phone) {
            payload.audioFile = this.createSingleForm.value.singleCallKey || ''; // For Single
        }
        //  else if (this.Round_Robin) {
        //     payload.audioFile = this.createRoundNumber.value.roundCallKey || ''; // For Round Robin
        // } else if (this.Simultaneous) {
        //     payload.audioFile = this.createSimalNumber.value.simalCallKey || ''; // For Simultaneous
        // }

        // Call the service to add tracking numbers
        this._campaignService.AddTrackingNumbers(payload).subscribe(
            (res: any) => {
                if (res.status) {
                    this.cursorDrop = false;
                    const obj = {
                        title: 'Tracking number added',
                        isFuseAlert: true,
                    };
                    this._campaignService.newTNalert.next(obj);
                    this._router.navigate(['/apps/tracking-number']);
                } else {
                    this.cursorDrop = false;
                    this.isSubmit = true;
                }
            },
            (error) => {
                this.cursorDrop = false;
                this.isSubmit = true;
                console.error('Error adding tracking number:', error);
            }
        );
    }

    /**
     * update Tracking Number
     */
    updateTrackingNumber() {
        this.cursorDrop = true;

        let numberSettings: any = {};
        let routeSettings: any = {};
        let voicemailEnabled = false;
        let IvrKey = 0;

        // Number Settings
        if (this.yearlyBilling) {
            numberSettings = {
                areacode: this.singleNumberForm.value.areacode,
                number: this.singleNumberForm.value.number,
                numberType: 'Single',
                numberName: this.singleNumberForm.value.numberName,
                spamguard: 0,
                recordCall: this.singleNumberForm.value.recordCall ? 1 : 0,
                sms: 0,
                trackingStatus: this.singleNumberForm.value.trackingStatus,
                callerIdType: this.singleNumberForm.value.CallerID,
            };
        }
        else {
            numberSettings = {
                areacode: this.poolNumberForm.value.areacode,
                myQuantityPool: this.poolNumberForm.value.quantity,
                number: '',
                numberType: 'Pool',
                numberName: this.poolNumberForm.value.tracking_number_name,
                spamguard: 0,
                trackingStatus: this.poolNumberForm.value.ad_active,
                recordCall: this.poolNumberForm.value.ad_recordCall ? 1 : 0,
                sms: 0,
                callerIdType: this.poolNumberForm.value.CallerPoolID,
            };
        }

        // Route Settings
        if (this.Single_Phone) {
            const singlePhoneVoice = this.createSingleForm.value.singleVoice ? 1 : 0;
            const singlePhoneWhisperToggle = this.createSingleForm.value.playWhisperBeforeCallSingle;
            const singleCallObject = {
                text:
                    this.createSingleForm.value
                        .playWhisperBeforeCallTextSingle || '',
                fileName: this.createSingleForm.value.singleCallKey || '',
            };

            routeSettings = {
                routingType: 1,
                voicemail: singlePhoneVoice,
                country: this.createSingleForm.value.country,
                countryCode: this.createSingleForm.value.countryCode,
                ringToNumber: this.sanitizePhoneNumber(this.createSingleForm.value.phonenumber),
                playWhisperBeforeCall: singlePhoneWhisperToggle ? true : false, // Ensure this is a boolean
                playWhisperBeforeCallText: singleCallObject,
            };
            voicemailEnabled = singlePhoneVoice === 1;
        }

        else if (this.IVR) {
            IvrKey = 1;
            routeSettings = {
                routingType: 4,
                // playWhisperBeforeCall: false, // Adjust based on your IVR logic
            };
            this.IVRSettingsArray = this.ivrFormArray.value.overFlowSection.map(
                (x) => {
                    const beforeCallObject = {
                        text: this.speechTextWhisper || '',
                        fileName:'',
                    };

                    return {
                        keyPress: x.keyPress,
                        country: x.country,
                        countryCode: x.countryCode,
                        IVRRingToNumber:
                            x.IVRRoutingType === 1 ? this.sanitizePhoneNumber(x.IVRRingToNumber) : '',
                        IVRAction: x.IVRAction,
                        IVRRoutingType: x.IVRRoutingType,
                        IVROverflowNumbers: x.IVROverflowNumbers.map((num) => ({
                            ...num,
                            number: num.number,
                        })),
                        playWhisperBeforeCall: x.playWhisperBeforeCall ? true : false,
                        playWhisperBeforeCallText: beforeCallObject,
                        // playWhisperForFirstTimeCaller: 0,
                        // playWhisperForFirstTimeCallerText: {
                        //     text: '',
                        //     fileName: '',
                        // },
                        // voicePromptOnSelection: 0,
                        // voicePromptOnSelectionText: { text: '', fileName: '' },
                    };
                }
            );

        }
        else {
            const simalVoice = this.createSimalNumber.value.SimalVoice ? 1 : 0;
            const simalPhoneWhisperToggle = this.createSimalNumber.value
                .playWhisperBeforeCallSimal
                ? 1
                : 0;

            const simalCallObject = {
                text:
                    this.createSimalNumber.value
                        .playWhisperBeforeCallTextSimal || '',
                fileName: this.createSimalNumber.value.simalCallKey || '',
            };

            routeSettings = {
                routingType: 3,
                voicemail: simalVoice,
                overflowNumbers: this.savedSimalData['overflowNumbers'].map(
                    (num) => ({
                        ...num,
                        number: num.number,
                    })
                ),
                playWhisperBeforeCall: simalPhoneWhisperToggle ? true : false, // Ensure this is a boolean
                playWhisperBeforeCallText: simalCallObject,
            };
            voicemailEnabled = simalVoice === 1;
        }

        const payload = {
            trackingId: this.trackingId,
            campaignId: this.campId,
            ivr_prompt_id:this.ivr_prompt_id || null,
            groupId: this.groupId, // Make sure this.groupId is set correctly
            audioFile: this.selectedIvrPromptFile || '',
            voicemailEnabled: voicemailEnabled,
            numberSettings: numberSettings,
            routeSettings: routeSettings,
            ivr: IvrKey,
            IVRSettings: this.IVRSettingsArray || [],
        };
        this._campaignService.updateTrackingNumbers(payload).subscribe(
            (res: any) => {
                if (res.status) {
                    this.cursorDrop = false;
                    const obj = {
                        title: 'Tracking number updated',
                        isFuseAlert: true,
                    };
                    this._campaignService.newTNalert.next(obj);
                    this._router.navigate(['/apps/tracking-number']);
                } else {
                    this.cursorDrop = false;
                    this.isSubmit = true;
                    console.error('Error updating tracking number:', res);
                    // Handle error case (e.g., show error message to user)
                }
            },
            (error) => {
                this.cursorDrop = false;
                this.isSubmit = true;
                console.error('Error updating tracking number:', error);
                // Handle error case (e.g., show error message to user)
            }
        );
    }

    /**
     * Delete TRACKING Number
     *
     */
    OnDeleteTN() {
        // Convert trackingId to a string if it is a number
        const trackingIdString = this.trackingId.toString();
        this._campaignService.DeleteTrackingNumbers(trackingIdString).subscribe(
            (respo: any) => {
                if (respo.status) {
                    this.modalService.dismissAll();
                    var obj = {
                        title: 'Tracking number deleted',
                        isFuseAlert: true,
                    };
                    // this._campaignService.CampaignsAndTnAlert.next(obj);
                    this._router.navigate(['/apps/tracking-number']);
                }
            },
            (error) => {
                // Handle error here (optional)
                console.error('Error deleting tracking number:', error);
            }
        );
    }

    /**
     * modal service
     * @param content modal Content/trigger
     */
    triggerModal(content) {
        this.modalService
            .open(content, {
                ariaLabelledBy: 'modal-basic-title',
                windowClass: 'no-pointer-events',
                animation: false,
            })
            .result.then(
                (res) => {
                    this.closeModal = `Closed with: ${res}`;
                },
                (res) => {
                    this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
                }
            );
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    ngOnDestroy() {
        this._campaignService.updateNewTrackingNnumberData.next('');
        this.sub.next('');
        this.sub.complete();
        this.singleNumberForm.reset();

        this.createSingleForm.reset();
    }
    private mapIVRSettings(UpdateResponse: any): void {
        this.ivrArray.clear();
        this.Round_Robin = false;
        this.Simultaneous = false;
        this.Single_Phone = false;
        this.IVR = true;
        const ivrSettings= UpdateResponse.IVRSettings;
        ivrSettings.forEach((setting, index) => {
          const ivrSettingsForm = this.createIVRSettingsForm(setting,UpdateResponse);
          this.ivrArray.push(ivrSettingsForm);
          this.handlePlayWhisperBeforeCall(setting, index);
          this.initializeCountrySelection(ivrSettingsForm);
        });
      }
      private initializeCountrySelection(form: FormGroup): void {
        const countryControl = form.get('country');
        if (countryControl && countryControl.value) {
          const selectedCountry = this.getCountryByIso(countryControl.value);
          if (selectedCountry) {
            countryControl.patchValue(selectedCountry.iso);
          } else {
            console.warn(`Country with ISO ${countryControl.value} not found.`);
            countryControl.patchValue(''); // Set to empty if country not found
          }
        }
      }
      private createIVRSettingsForm(setting: any,UpdateResponse:any): FormGroup {
        return this._formBuilder.group({
        country: [UpdateResponse?.country || ''],
          countryCode: [UpdateResponse?.countryCode],
          keyPress: [setting.keyValue],
          IVRRingToNumber: [setting.ringToNumber],
          IVRAction: [setting.action],
          IVRRoutingType: [setting.IVRRoutingType],
          playWhisperBeforeCall: [this.convertToBoolean(setting.isPlayWhisperBeforeCall)],
          playWhisperBeforeCallText: [setting.playWhisperBeforeCallText || ''],
          BeforeCallKey: [setting.playWhisperBeforeCallFile],
          BeforeCallUrl: [''],
          BeforeCallAudio: [setting.BeforeCallAudio],
          IVROverflowNumbers: this._formBuilder.array([]),
        });
      }
      private handlePlayWhisperBeforeCall(setting: any, index: number): void {
        const control = this.ivrArray.at(index);
        const playWhisperBeforeCall = this.convertToBoolean(setting.isPlayWhisperBeforeCall);
        if (setting.playWhisperBeforeCallFile) {
          control.patchValue({
            BeforeCallKey: setting.playWhisperBeforeCallFile,
            playWhisperBeforeCallText: setting.playWhisperBeforeCallFile,
          });

          this.fileNameBeforeCall = setting.playWhisperBeforeCallFile;

          this.fetchAudioUrl(setting.playWhisperBeforeCallFile, control);
        }

        if (playWhisperBeforeCall) {
            control.get('playWhisperBeforeCallText').enable();
        } else {
            control.get('playWhisperBeforeCallText').disable();
        }
      }
      private convertToBoolean(value: any): boolean {
        return value === true || value === 1 || value === '1' || value === 'true';
      }
      private fetchAudioUrl(objectKey: string, control: AbstractControl): void {
        this._campaignService.getAudioUrl({ objectKey }).subscribe(
          (res: any) => {
            if (res?.status === 'success') {
              control.patchValue({
                BeforeCallUrl: res.body.URL,
              });
              control.get('playWhisperBeforeCallText').disable();
            }
          },
          (error) => {
            console.error('Error fetching audio URL:', error);
          }
        );
      }
}
