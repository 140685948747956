import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {environment} from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SubgroupsService {

  subGroupList = new BehaviorSubject<any>({});
  apiUrl =environment.apiUrl
  constructor(private http: HttpClient) { }


  getSubgroupsList(data){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post( `${this.apiUrl}groups/getGroup`, data, { headers });
  }

}
