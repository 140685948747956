import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { GroupsDatabaseService } from './groups-database.service';
import { GroupFlatNode } from './models/GroupFlatNode';
import { GroupNode } from './models/GroupNode';
import * as moment from 'moment-timezone';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

class CustomDateAdapter extends MomentDateAdapter {
	getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
		return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	}
}

export const MY_DATE_FORMATS = {
	parse: {
		dateInput: 'l, L, LTS',
	},
	display: {
		dateInput: 'MM/DD/YYYY hh:mm A ',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LLL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@Component({
	selector: 'app-groups-tree',
	templateUrl: './groups-tree.component.html',
	styleUrls: ['./groups-tree.component.scss'],
	providers: [
		{ provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } },
		{ provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
	],
})
export class GroupsTreeComponent {
	@ViewChild('treeContainer') treeContainer: ElementRef;
	/** Map from flat node to nested node. This helps us finding the nested node to be modified */
	flatNodeMap = new Map<GroupFlatNode, GroupNode>();
	/** Map from nested node to flattened node. This helps us to keep the same object for selection */
	nestedNodeMap = new Map<GroupNode, GroupFlatNode>();
	treeControl: FlatTreeControl<GroupFlatNode>;
	treeFlattener: MatTreeFlattener<GroupNode, GroupFlatNode>;
	dataSource: MatTreeFlatDataSource<GroupNode, GroupFlatNode>;
	/** The selection for checklist */
	checklistSelection: SelectionModel<string>;
	selection_changed = false;
	clicked_node: GroupFlatNode | null;
	clicked_num = 0;
	tree_data_save: GroupNode[];
	currentFilterText: string = '';
	//groupTreeDetailsForm: FormGroup;
	currentTimeZone = 'America/Denver';
	todayDate: moment.Moment = moment().tz(this.currentTimeZone);
	minDate: moment.Moment;
	maxDate: moment.Moment = moment().tz(this.currentTimeZone);
	startDateError: boolean = false;
	LLM_confirmation_check: boolean = false;
	insightType = '';
	isBtnDisabled = false;
	getLevel = (node: GroupFlatNode) => node?.level;

	isExpandable = (node: GroupFlatNode) => node.expandable;

	getChildren = (node: GroupNode): GroupNode[] => node.children;

	hasChild = (_: number, _nodeData: GroupFlatNode) => _nodeData.expandable;

	hasNoContent = (_: number, _nodeData: GroupFlatNode) => _nodeData.item === '';
	//start_date_change: boolean = false;

	constructor(private fb: FormBuilder, private _database: GroupsDatabaseService, private dialogRef: MatDialogRef<GroupsTreeComponent>, @Inject(MAT_DIALOG_DATA) public dialog_data: any) {
		// this.groupTreeDetailsForm = this.fb.group({
		// 	start_date: [moment().tz(this.currentTimeZone)],
		// 	start_hours: ['00', [Validators.pattern(/((0[0-9])|(1[0-2]))/)]],
		// 	start_minutes: ['00', [Validators.pattern(/([0-5][0-9])/)]],
		// 	start_meridiem: ['AM'],
		// });
		//this.start_date_change = false;

		// this.groupTreeDetailsForm.get('start_date').valueChanges.subscribe(() => {
		// 	// Update the validity of the button whenever the start_date changes
		// 	console.log('sssss');
		// 	this.start_date_change = true;
		// });
		this._database.initGroups(this.dialog_data.groups);

		this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
		this.treeControl = new FlatTreeControl<GroupFlatNode>(this.getLevel, this.isExpandable);
		this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

		_database.dataChange.subscribe((data) => {
			console.log('dataaa', this.dialog_data);
			this.dataSource.data = data;
			this.insightType = this.dialog_data.insight_type;
			this.tree_data_save = JSON.parse(JSON.stringify(data));
			this.checklistSelection = new SelectionModel<string>(!this.dialog_data.single_select); /* single / multiple SelectionModel */
			const selected_nodes = this.dialog_data.selected_nodes.map((el) => el.id);
            console.log(selected_nodes)
			this.checklistSelection.select(...selected_nodes);
			// need to expand all nodes initially to set selection status for all child nodes (else diplay: none)
			// this.treeControl.expandAll();
			// select ALL nodes on first load
			this.treeControl.collapseAll();

			// if (this.dialog_data.first_open && data.length) {
			//   this.treeControl.dataNodes.forEach((node) => this.checklistSelection.select(node));
			// }
			// need short timer to let all nodes expand before collapsing them
			setTimeout(() => {
				this.treeControl.collapseAll();
				if (this.dialog_data.single_select && selected_nodes.length) {
					// expand parent node of current selection
					const current_node = this.treeControl.dataNodes.find((el) => el.id === selected_nodes[0]);
					const parent_node = this.getParentNode(current_node);
					if (parent_node) this.treeControl.expand(parent_node);
				}
				// expand ONLY root nodes by DEFAULT
				this.treeControl.dataNodes.forEach((el) => {
					if (el.level === 0) this.treeControl.expand(el);
				});
			}, 100);
		});
	}

	isButtonDisabled(): boolean {
		// if (this.start_date_change) {
		// 	this.isBtnDisabled = false;
		// 	return false;
		// } else
		if (this.selection_changed) {
			this.isBtnDisabled = false;
			return false;
		}
		this.isBtnDisabled = true;
		return true;
	}
	// getButtonText() {
	// 	if (this.insightType === 'LLM') {
	// 		return this.isBtnDisabled ? 'Save' : 'Save and add to queue';
	// 	}
	// 	return 'Apply';
	// }

	/** Transformer to convert nested node to flat node. Record the nodes in maps for later use.*/
	transformer = (node: GroupNode, level: number) => {
		const existingNode = this.nestedNodeMap.get(node);
		const flatNode = existingNode && existingNode.item === node.item ? existingNode : new GroupFlatNode();
		flatNode.item = node.item;
		flatNode.level = level;
		flatNode.id = node.id;
		flatNode.isDisabled = node.isDisabled ? node.isDisabled : false;
		flatNode.expandable = !!node.children?.length;
		this.flatNodeMap.set(flatNode, node);
		this.nestedNodeMap.set(node, flatNode);
		return flatNode;
	};

	/** Whether all the descendants of the node are selected. */
	descendantsAllSelected(node: GroupFlatNode): boolean {
		const descendants = this.treeControl.getDescendants(node);
		const descAllSelected =
			descendants.length > 0 &&
			descendants.every((child) => {
				return this.checklistSelection.isSelected(child.item);
			});
		return descAllSelected;
	}

	/** Whether part of the descendants are selected */
	descendantsPartiallySelected(node: GroupFlatNode): boolean {
		//
		const descendants = this.treeControl.getDescendants(node);
		const result = descendants.some((child) => this.checklistSelection.isSelected(child.id));
		return result && !this.checklistSelection.isSelected(node.id);
	}

	selectionToggle(node: GroupFlatNode) {
		if (node.isDisabled == undefined || node.isDisabled == false) {
			if (this.dialog_data.single_select) {
				this.checklistSelection.select(node.id);
				this.selection_changed = this.getApplyBtnVisibility();
			} else if (node.expandable) this.groupSelectionToggle(node);
			else this.groupLeafItemSelectionToggle(node);
		}
	}

	processItems(items: any[], targetId: number, level: number = 0): any[] {
		let processedItems: any[] = [];

		const findAndProcessItems = (nodes: any[], currentLevel: number) => {
			nodes.forEach((node) => {
				if (node.id === targetId) {
					processChildItems(node.children || [], currentLevel + 1);
				} else if (node.children && node.children.length > 0) {
					findAndProcessItems(node.children, currentLevel + 1);
				}
			});
		};

		const processChildItems = (children: any[], currentLevel: number) => {
			children.forEach((child) => {
				let newItem: any = {
					item: child.item.trim(),
					level: currentLevel,
					id: child.id,
					isDisabled: child.isDisabled,
					expandable: child.children && child.children.length > 0 ? true : false,
				};
				processedItems.push(newItem);

				if (child.children && child.children.length > 0) {
					processChildItems(child.children, currentLevel + 1);
				}
			});
		};

		findAndProcessItems(items, level);

		return processedItems;
	}
	/** Toggle the Group item selection. Select/deselect all the descendants node */
	groupSelectionToggle(node: GroupFlatNode): void {
		// first-selection, select parent node only
		if ((!this.clicked_node || this.clicked_node !== node) && !this.checklistSelection.isSelected(node.id)) {
			this.clicked_node = node;
			this.checklistSelection.select(node.id);
		} else {
			// second-selection, select parent & all children
			if (this.clicked_num === 0 && this.checklistSelection.isSelected(node.id) && this.clicked_node) {
				this.checklistSelection.select(node.id);
				this.clicked_num = 1;
			} else {
				// third-selection, deselect parent & all children
				this.clicked_node = null;
				this.clicked_num = 0;
				this.checklistSelection.deselect(node.id);
			}
			// let descendants = this.treeControl.getDescendants(node).filter((node) => this.showNode(node)); // showNode(node) filters out invisible nodes (in case group filter is applied)
			const processedData = this.processItems(this.tree_data_save, node.id);
			let descendants = processedData;

			descendants = descendants.filter((d) => d.isDisabled != true);
			this.checklistSelection.isSelected(node.id) ? this.checklistSelection.select(...descendants.map((el) => el.id)) : this.checklistSelection.deselect(...descendants.map((el) => el.id));
		}
		// Force update for the parent
		// this.checkAllParentsSelection(node);
		if (this.dialog_data.apply_btn_visibilty == true) {
			this.selection_changed = true;
		} else {
			this.selection_changed = this.getApplyBtnVisibility();
		}
	}

	/** Toggle a leaf Group item selection. Check all the parents to see if they changed */
	groupLeafItemSelectionToggle(node: GroupFlatNode): void {
		const found_node = this.treeControl.dataNodes.find((el) => el.expandable === node.expandable && el.level === node.level && el.item === node.item && el.id === node.id);
		this.checklistSelection.toggle(found_node!.id);
		// this.checkAllParentsSelection(node);
		if (this.dialog_data.apply_btn_visibilty == true) {
			this.selection_changed = true;
		} else {
			this.selection_changed = this.getApplyBtnVisibility();
		}
	}

	getApplyBtnVisibility() {
		let enableBtn = false;
		this.treeControl.dataNodes.forEach((node) => {
			if (this.checklistSelection.isSelected(node.id)) enableBtn = true;
		});
		return enableBtn;
	}

	/** Checks all the parents when a leaf node is selected/unselected */
	checkAllParentsSelection(node: GroupFlatNode): void {
		let parent: GroupFlatNode | null = this.getParentNode(node);
		while (parent) {
			this.checkRootNodeSelection(parent);
			parent = this.getParentNode(parent);
		}
	}

	/** Check root node checked state and change it accordingly */
	checkRootNodeSelection(node: GroupFlatNode): void {
		const nodeSelected = this.checklistSelection.isSelected(node.item);
		const descendants = this.treeControl.getDescendants(node);
		const descAnySelected = descendants.some((child) => {
			return this.checklistSelection.isSelected(child.item);
		});
		if (nodeSelected) {
			this.checklistSelection.select(node.item);
		}
		if (descAnySelected) {
			this.checklistSelection.select(node.item);
		}
	}

	/** Get the parent node of a node */
	getParentNode(node: GroupFlatNode): GroupFlatNode | null {
		const currentLevel = this.getLevel(node);
		if (currentLevel < 1) {
			return null;
		}
		const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
		for (let i = startIndex; i >= 0; i--) {
			const currentNode = this.treeControl.dataNodes[i];
			if (this.getLevel(currentNode) < currentLevel) {
				return currentNode;
			}
		}
		return null;
	}

	filterChanged(event) {
		this.currentFilterText = event.toLowerCase();

		if (this.currentFilterText) {
			this.dataSource.data = this.filterTreeData(this.tree_data_save);
			console.log(this.tree_data_save);
			this.treeControl.expandAll();
		} else {
			this.dataSource.data = [...this.tree_data_save];
			this.treeControl.collapseAll();
			this.treeControl.dataNodes.forEach((el) => {
				if (el.level === 0) this.treeControl.expand(el);
			});
		}
	}

	filterTreeData(nodes: any[]): any[] {
		return nodes
			.filter((node) => this.nodeMatchesFilter(node))
			.map((node) => {
				const clonedNode = { ...node };
				if (clonedNode.children) {
					clonedNode.children = this.filterTreeData(clonedNode.children);
				}
				return clonedNode;
			});
	}

	nodeMatchesFilter(node: any): boolean {
		if (node.item.toLowerCase().includes(this.currentFilterText)) {
			return true;
		}
		if (node.children) {
			return node.children.some((child) => this.nodeMatchesFilter(child));
		}
		return false;
	}

	showNode(node: GroupFlatNode) {
		// show node if filter is empty
		if (this.currentFilterText === '') return true;
		// show node if node text contains filter text
		if (node.item.toLowerCase().includes(this.currentFilterText)) return true;
		// show if current node is 3rd lvl & its 2nd lvl parent is matched
		const parent_node = this.getParentNode(node);
		if (node.level === 2 && parent_node?.item.toLowerCase().includes(this.currentFilterText)) return true;
		// show node if any of the descendant text matches filter text
		const descendants = this.treeControl.getDescendants(node);
		const result = descendants.some((el) => el.item.toLowerCase().includes(this.currentFilterText));
		return result;
	}

	// openStartPicker() {
	// 	let start_hours = moment(this.groupTreeDetailsForm.get('start_date').value).format('hh');
	// 	this.groupTreeDetailsForm.get('start_hours').setValue(start_hours);
	// 	let start_minutes = moment(this.groupTreeDetailsForm.get('start_date').value).format('mm');
	// 	this.groupTreeDetailsForm.get('start_minutes').setValue(start_minutes);
	// 	let start_meridiem = moment(this.groupTreeDetailsForm.get('start_date').value).format('A');
	// 	this.groupTreeDetailsForm.get('start_meridiem').setValue(start_meridiem);
	// }

	// onDateChange() {
	// 	let start_date = this.groupTreeDetailsForm.get('start_date').value;
	// 	start_date = moment(start_date).set('hour', this.groupTreeDetailsForm.get('start_hours').value);
	// 	start_date = moment(start_date).set('minute', this.groupTreeDetailsForm.get('start_minutes').value);
	// 	if (this.groupTreeDetailsForm.get('start_meridiem').value == 'PM' && this.groupTreeDetailsForm.get('start_hours').value != '12') {
	// 		start_date = moment(start_date).add('hours', 12);
	// 	} else if (this.groupTreeDetailsForm.get('start_meridiem').value == 'AM' && this.groupTreeDetailsForm.get('start_hours').value == '12') {
	// 		start_date = moment(start_date).add('hours', 12);
	// 	}
	// 	this.groupTreeDetailsForm.get('start_date').patchValue(start_date);
	// }

	// onStartPickerClear(datepicker: MatDatepicker<any>) {
	// 	datepicker.select(null);
	// 	datepicker.close();
	// 	this.groupTreeDetailsForm.get('start_date').patchValue(moment().tz(this.currentTimeZone));
	// 	let start_hours = moment(this.groupTreeDetailsForm.get('start_date').value).format('hh');
	// 	this.groupTreeDetailsForm.get('start_hours').setValue(start_hours);
	// 	let start_minutes = moment(this.groupTreeDetailsForm.get('start_date').value).format('mm');
	// 	this.groupTreeDetailsForm.get('start_minutes').setValue(start_minutes);
	// 	let start_meridiem = moment(this.groupTreeDetailsForm.get('start_date').value).format('A');
	// 	this.groupTreeDetailsForm.get('start_meridiem').setValue(start_meridiem);
	// }

	closeModal() {
		this.currentFilterText = '';
		this.filterChanged('');
		const selection_data = {};
		selection_data['groups_selected'] = this.checklistSelection.selected;
		const selected_nodes = this.treeControl.dataNodes.filter((node) => this.checklistSelection.isSelected(node.id));
		selection_data['selected_nodes'] = selected_nodes;
		// if (this.dialog_data.page && this.dialog_data.page == 'insights') {
		// 	selection_data['start_date'] = this.groupTreeDetailsForm.get('start_date').value.format('YYYY-MM-DD HH:mm:ss');
		// }

		if (this.dialog_data.single_select) {
			if (selected_nodes[0].level === 1) {
				selection_data['parent_node'] = this.getParentNode(selected_nodes[0]);
			}
			if (selected_nodes[0].level === 2) {
				selection_data['parent_node'] = this.getParentNode(selected_nodes[0]);
				selection_data['top_node'] = this.getParentNode(selection_data['parent_node']);
			}
		}
		this.dialogRef.close(selection_data);
	}
}
