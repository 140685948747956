import { concat } from 'rxjs';
import { Component, EventEmitter, OnInit, Output, Input, AfterViewInit, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
// import { SharedService } from '../shared.service';
import { GroupsDatabaseService } from 'app/shared/groups-tree/groups-database.service';
import { GroupFlatNode } from 'app/shared/groups-tree/models/GroupFlatNode';
import { SharedService } from 'app/shared/shared.service';
import { GroupsTreeComponent } from 'app/shared/groups-tree/groups-tree.component';
// import { SearchBarComponent } from '../search-bar/search-bar.component';
// import { GlobalSearchComponent } from '../global-search/global-search.component';
// import { NotificationComponent } from '../../admin/components/actionable-insights/notification/notification.component';
// import { ActionableInsightsService } from '../../admin/components/actionable-insights/actionable-insights.service';

@Component({
	selector: 'app-bread-crumbs',
	templateUrl: './bread-crumbs.component.html',
	styleUrls: ['./bread-crumbs.component.scss'],
})
export class BreadCrumbsComponent implements OnInit, AfterViewInit {
	@Output() groupChangeEvent = new EventEmitter<any>();
	@Input() groupSelectionType: string = 'single';
	@Input() showSearchbar: string;
	@Input() showGroupsTrees: string;
	@Input() searchTags: any;
	@Input() selectionChanged: boolean = false;
	@Output() filterChangeEvent = new EventEmitter<any>();
	isLoading: boolean = false;
	isSearching: boolean = false;
	selectedGroups: GroupFlatNode[];
	selectedTree: any;
	userData: any;
	isAdmin: boolean = false;
	level1Item: string = '';
	level2Item: string = '';
	level3Item: string = '';
	whiteLableActive: boolean = false;
	countLevel2 = 0;
	countLevel3 = 0;
	unreadMessageCount: number = 0;
	@Input() showBellIcon: boolean = false;
	constructor(
		private router: Router,
		private groupsDatabaseService: GroupsDatabaseService,
		private dialog: MatDialog,
		private sharedService: SharedService,
		private cdr: ChangeDetectorRef // private actionableInsightsService: ActionableInsightsService,
	) {}

	ngOnInit(): void {
		this.selectedGroups = JSON.parse(localStorage.getItem(`selected_groups_${this.groupSelectionType}`)) || [];
		console.log(this.selectedGroups)
		this.selectedTree = JSON.parse(localStorage.getItem(`selected_tree_${this.groupSelectionType}`));
		this.getSelectedGroupTree();
		// this.sharedService.groupSelectionType = this.groupSelectionType;
		if (localStorage.getItem('adminInfo')) {
			this.isAdmin = true;
		} else {
			this.isAdmin = false;
		}

		if (localStorage.getItem('adminInfo')) this.isAdmin = true;
		else this.isAdmin = false;
		// this.fetchUnreadNotificationCount();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes && changes.selectionChanged && changes.selectionChanged.currentValue) {
			this.ngOnInit();
		}
	}

	ngAfterViewInit() {

	}

	SearchingFun(e) {
		this.isSearching = e;
	}
	openSearchModal() {
		// const dialogRef = this.dialog.open(GlobalSearchComponent, {
		// 	panelClass: 'search-modal-container',
		// 	backdropClass: 'dialog-blank-backdrop',
		// 	autoFocus: true,
		// 	position: { top: '20px', right: '40px' },
		// 	width: '77.2vw',
		// 	maxWidth: 'inherit',
		// 	minHeight: '10vh',
		// 	maxHeight: '60vh',
		// });
		// dialogRef.afterClosed().subscribe((data) => {});
	}
	openGroupsModal() {
		const dialogRef = this.dialog.open(GroupsTreeComponent, {
			panelClass: 'group-tree-modal',
			data: {
				first_open: true,
				single_select: this.groupSelectionType === 'single',
				selected_nodes: this.selectedGroups,
			},
			minHeight: '90vh',
			height: '90vh',
			width: '40vw',
		});

		dialogRef.afterClosed().subscribe((data) => {
			if (data) {
				// this.syncMultipleAndSingleGroupsSelectors(data);
				this.selectedGroups = data.selected_nodes;
                console.log('groupSelectionType', this.groupSelectionType);
				localStorage.setItem(`selected_groups_${this.groupSelectionType}`, JSON.stringify(data['selected_nodes']));
				this.selectedTree = data;
				localStorage.setItem(`selected_tree_${this.groupSelectionType}`, JSON.stringify(data));
				this.getSelectedGroupTree();
				this.groupChangeEvent.emit(true);
				this.sharedService.setGroupChange(true);
			}
		});
	}

	getSelectedGroupTree() {
		if (this.selectedGroups.length > 0 && this.selectedGroups[0]) {
			if (this.groupSelectionType === 'single') {
				if (!this.selectedTree) {
					this.level1Item = this.selectedGroups[0]['item'] || this.selectedGroups[0]['name'];
					this.level2Item = '';
					this.level3Item = '';
				}
				if (this.selectedTree && this.selectedTree['top_node']) {
					this.level1Item = this.selectedTree['top_node']['item'];
					this.level2Item = this.selectedTree['parent_node']['item'];
					this.level3Item = this.selectedGroups[0]['item'];
				}
				if (this.selectedTree && !this.selectedTree['top_node'] && this.selectedTree['parent_node']) {
					this.level1Item = this.selectedTree['parent_node']['item'];
					this.level2Item = this.selectedGroups[0]['item'];
					this.level3Item = '';
				}
				if (this.selectedTree && !this.selectedTree['top_node'] && !this.selectedTree['parent_node']) {
					this.level1Item = this.selectedGroups[0]['item'] || this.selectedGroups[0]['name'];
					this.level2Item = '';
					this.level3Item = '';
				}
			} else {
				this.countLevel2 = 0;
				this.countLevel3 = 0;
				if (!this.selectedTree) {
					let treeData = JSON.parse(localStorage.getItem('groups'));
					const data = this.groupsDatabaseService.buildFileTree(treeData, '0');
					if (data && data.length > 0 && data[0].children) {
						data[0].children.map((item) => {
							this.countLevel2 += 1;
							if (item.children?.length > 0) {
								this.countLevel3 += item.children?.length;
							}
							this.level2Item = 'Groups';
							this.level3Item = 'Subgroups';
						});
					}
				} else {
					this.selectedTree.selected_nodes.map((item) => {
						if (item) {
							if (item.level == 0 || item.group_level == 1) {
								this.level1Item = item.item || item.text;
							}
							if (item.level == 1 || item.group_level == 2) {
								this.countLevel2 += 1;
							}
							if (item.level == 2 || item.group_level == 3) {
								this.countLevel3 += 1;
							}
							this.level2Item = 'Groups';
							this.level3Item = 'Subgroups';
						}
					});
				}
console.log('level1Item', this.level1Item);
				if (this.level1Item.length === 0) {
					this.level1Item = JSON.parse(localStorage.getItem('main_group')).name;
				}
			}
		}
	}

	logOut(): void {
		// this.auth.logout();
	}

	switchUser() {
		localStorage.clear();
		localStorage.setItem('token', localStorage.getItem('admin_token'));
		this.router.navigate(['/administrator']);
	}
	goToSettings() {
		this.router.navigate(['/admin/profile']);
	}

	syncMultipleAndSingleGroupsSelectors(selectedData) {
		let selectedTreeSingle = JSON.parse(localStorage.getItem('selected_tree_single') || '{"groups_selected" : []}');
		let selectedGroupsSingle = JSON.parse(localStorage.getItem('selected_groups_single'));
		let selectedTreeMultiple = JSON.parse(localStorage.getItem('selected_tree_multiple') || '{"groups_selected" : []}');
		let selectedGroupsMultiple = JSON.parse(localStorage.getItem('selected_groups_multiple'));
		let mainGroup = JSON.parse(localStorage.getItem('main_group'));

		// filter already selected single select items from the multiple select items
		let groupsSelected = selectedTreeSingle?.groups_selected.concat(selectedTreeMultiple?.groups_selected).filter((value, index, self) => self.indexOf(value) === self.lastIndexOf(value));

		let syncedSelectedData: any;

		if (this.groupSelectionType === 'single') {
            console.log()
            localStorage.setItem(`selected_groups_single`, JSON.stringify(syncedSelectedData['selected_nodes']));
			localStorage.setItem(`selected_tree_single`, JSON.stringify(syncedSelectedData));

		} else if (selectedData.selected_nodes[0].level === 0) {
			// items less than two selected in multiple select
			if (selectedData.groups_selected.length > 2) {
				syncedSelectedData = {
					selected_nodes: [selectedData.selected_nodes[0]], // index 0 because we are selecting the first level 0 item  item in the array
					groups_selected: [groupsSelected[0]], // index 0 because we are selecting the first level 0 item in the array,
				};
			} else if (selectedData.groups_selected.length <= 2) {
				// items less than one selected in multiple select
				syncedSelectedData = {
					// index 0 because we are selecting the first level 0 item in the array and 1 because we need the second element for level 1 item
					selected_nodes: [selectedData.selected_nodes[1] ? selectedData.selected_nodes[1] : selectedData.selected_nodes[0]],
					groups_selected: [groupsSelected[1] ? groupsSelected[1] : groupsSelected[0]],
					parent_node: { item: mainGroup.name, level: mainGroup.group_level, id: mainGroup.id },
				};
			}
            localStorage.setItem(`selected_groups_multiple`, JSON.stringify(selectedData['selected_nodes']));
			localStorage.setItem(`selected_tree_multiple`, JSON.stringify(selectedData));
		} else {
			let selected_group_single;
			if (selectedData.groups_selected.length < 2) {
				selected_group_single = [{ item: selectedData['selected_nodes'][0].item, level: selectedData['selected_nodes'][0].level, id: selectedData['selected_nodes'][0].id }];
				syncedSelectedData = {
					selected_nodes: selected_group_single,
					groups_selected: selectedData['groups_selected'],
					parent_node: { item: mainGroup.name, level: mainGroup.group_level, id: mainGroup.id },
				};
			} else {
				selected_group_single = [{ item: mainGroup.name, group_level: mainGroup.group_level, id: mainGroup.id }];
				syncedSelectedData = {
					selected_nodes: selected_group_single,
					groups_selected: [],
				};
			}
			localStorage.setItem(`selected_groups_single`, JSON.stringify(selected_group_single));
			localStorage.setItem(`selected_tree_single`, JSON.stringify(syncedSelectedData));
		}
	}

	// openNotificationModal() {
	// 	const dialogRef = this.dialog.open(NotificationComponent, {
	// 		width: '510px',
	// 		position: {
	// 			right: '20px',
	// 			top: '91px',
	// 		},
	// 		minHeight: this.unreadMessageCount > 0 ? '40vh' : '10vh',
	// 		maxHeight: '80vh',  // Maximum height for the modal
	// 	});
	// 	dialogRef.afterClosed().subscribe(() => {
	// 		// this.fetchUnreadNotificationCount();
	// 	});
	// }

	// fetchUnreadNotificationCount() {
	// 	this.actionableInsightsService.getUnreadNotificationCount().subscribe(
	// 	  (response: any) => {
	// 		if (response.status === 'success') {
	// 		  const countString = response.json.count;
	// 		  // Convert countString to number
	// 		  const count = parseInt(countString, 10);
	// 		  // Check if count is a valid number
	// 		  if (!isNaN(count)) {
	// 			this.unreadMessageCount = count;
	// 		  }
	// 		} else {
	// 		  console.error('Error fetching unread notification count:', response.err);
	// 		}
	// 	  },
	// 	  (error) => {
	// 		console.error('Error fetching unread notification count:', error);
	// 	  }
	// 	);
	//   }
}
