<div
    class="fixed lg:sticky top-0 bottom-0 lg:left-full w-full sm:w-96 lg:w-16 lg:h-screen lg:shadow"
>
    <div
        class="flex flex-col w-full sm:w-96 h-full transition-transform duration-400 ease-drawer bg-card"
        [ngClass]="{
            '-translate-x-full sm:-translate-x-96 lg:-translate-x-80 shadow':
                opened,
            'translate-x-0': !opened
        }"
    >
 
        <div
            class="quick-chat-header flex flex-0 items-center justify-start cursor-pointer"
            (click)="toggle()"
        >
            <div
                *ngIf="notificationOnTop()"
                class="relative flex-0 w-2 h-2 -ml-0.5 rounded-full ring-2 ring-bg-card dark:ring-gray-900 bg-primary dark:bg-primary-500 text-on-primary"
                style="left: 25px; top: -10px"
                [class.ring-primary-50]="noti"
            ></div>
            <ng-container *ngIf="!getSelectedChat || !opened">
                <div class="flex flex-auto items-center justify-center">
                    <div class="flex flex-0 items-center justify-center w-16">
                        <mat-icon
                            class="icon-size-6"
                            [svgIcon]="'heroicons_outline:chat-alt-2'"
                        ></mat-icon>
                    </div>
                    <div class="text-lg font-medium text-secondary">
                        Team Chat
                    </div>
                    <button class="ml-auto mr-4" mat-icon-button>
                        <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="opened && getSelectedChat">
                <div class="flex flex-auto items-center ml-3">
                    <div
                        class="relative flex flex-0 items-center justify-center w-10 h-10"
                    >
                        <ng-container *ngIf="this.chatSelected.profileImage">
                            <img
                                class="w-full h-full rounded-full object-cover"
                                [src]="this.chatSelected.profileImage"
                                alt="Contact avatar"
                            />
                        </ng-container>
                        <ng-container
                            *ngIf="this.chatSelected.profileImage === null"
                        >
                            <div
                                class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200"
                            >
                                {{ this.chatSelected.username.trim().charAt(0) }}
                            </div>
                        </ng-container>
                    </div>
                    <div class="ml-4 text-lg font-medium leading-5 truncate">
                        {{ this.chatSelected.username }}
                    </div>
                    <button class="ml-auto mr-4" mat-icon-button>
                        <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
                    </button>
                </div>
            </ng-container>
        </div>

        <div class="flex flex-auto border-t overflow-hidden">
            <div
                class="flex-0 w-16 h-full overflow-hidden overflow-y-auto overscroll-y-contain sm:overflow-hidden sm:overscroll-auto"
                fuseScrollbar
                [fuseScrollbarOptions]="{ wheelPropagation: false }"
            >
                <div class="flex-auto">
                    <ng-container
                        *ngFor="let chat of updatedUsers; let i = index"
                    >
                        <div
                            class="flex items-center py-3 px-4 cursor-pointer"
                            [ngClass]="{
                                'hover:bg-gray-100 dark:hover:bg-hover':
                                    !recieverEmail ||
                                    recieverEmail !== chat.email,
                                'bg-primary-50 dark:bg-hover':
                                    recieverEmail &&
                                    recieverEmail === chat.email
                            }"
                            (click)="selectChat(chat.email)"
                        >
                            <div
                                class="relative flex flex-0 items-center justify-center w-8 h-8"
                            >
                                <ng-container
                                    *ngIf="
                                        getNotificationTrue(chat.email) ===
                                        chat.email
                                    "
                                >
                                    <div
                                        class="absolute bottom-0 right-0 flex-0 w-2 h-2 -ml-0.5 rounded-full ring-2 ring-bg-card dark:ring-gray-900 bg-primary dark:bg-primary-500 text-on-primary"
                                        [class.ring-primary-50]="
                                            Message &&
                                            recieverEmail === chat.email
                                        "
                                    ></div>
                                </ng-container>

                                <ng-container
                                    *ngIf="GetUnreadCounts(chat.email)"
                                >
                                    <div
                                        class="absolute flex justify-center p-2 items-center top-0 bg-red-800 left-0 flex-0 w-4 h-4 -ml-1 rounded-full"
                                    >
                                        <div
                                            class="absolute"
                                            style="
                                                color: white;
                                                font-size: x-small;
                                            "
                                        >
                                            {{ GetUnreadCounts(chat.email) }}
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="chat.profileImage">
                                    <img
                                        class="w-full h-full rounded-full object-cover"
                                        [src]="chat.profileImage"
                                        alt="Contact avatar"
                                    />
                                </ng-container>
                                <ng-container *ngIf="!chat.profileImage">
                                    <div
                                        class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200"
                                    >
                                        {{ chat.username.charAt(0) }}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div
                *ngIf="!getSelectedChat"
                class="flex flex-col flex-auto items-center justify-center w-full h-full p-4"
            >
                <mat-icon
                    class="icon-size-24"
                    [svgIcon]="'heroicons_outline:chat'"
                ></mat-icon>
                <div
                    class="mt-4 text-xl text-center font-medium tracking-tight text-secondary"
                >
                    Select a conversation
                </div>
            </div>

            <div
                *ngIf="getSelectedChat"
                class="flex flex-col flex-auto border-l overflow-hidden bg-gray-50 dark:bg-transparent"
            >
                <ng-container>
                    <div
                        class="flex flex-col-reverse overflow-y-auto  h-[700px]"
                    >
                        <div class="flex flex-col flex-auto shrink p-6">
                            <ng-container
                                *ngFor="
                                    let message of objectsOfChat;
                                    let i = index;
                                    let first = first;
                                    let last = last
                                "
                            >
                                <div
                                    *ngIf="
                                        message.message &&
                                        message.channel ===
                                            this.new_ChanelVariable
                                    "
                                    class="flex flex-col overflow-x-hidden"
                                    [ngClass]="{
                                        'items-end':
                                            message.id === this.senderEmail,
                                        'items-start':
                                            message.id !== this.senderEmail,
                                        'mt-0.5':
                                            i > 0 &&
                                            objectsOfChat[i].id ===
                                                this.senderEmail,
                                        'mt-3':
                                            i > 0 &&
                                            objectsOfChat[i].id !==
                                                this.senderEmail
                                    }"
                                >
                                    <div
                                        class="relative max-w-3/4 px-3 py-2 rounded-lg"
                                        [ngClass]="{
                                            'bg-blue-500 text-blue-50':
                                                message.id === this.senderEmail,
                                            'bg-gray-500 text-gray-50':
                                                message.id !== this.senderEmail
                                        }"
                                    >
                                        <ng-container
                                            *ngIf="
                                                last ||
                                                (objectsOfChat[i].id !==
                                                    this.senderEmail &&
                                                    message.channel ===
                                                        this.new_ChanelVariable)
                                            "
                                        >
                                            <div
                                                class="absolute bottom-0 w-3"
                                                [ngClass]="{
                                                    'text-blue-500 -right-1 -mr-px mb-px':
                                                        message.id ===
                                                        this.senderEmail,
                                                    'text-gray-500 -left-1 -ml-px mb-px -scale-x-1':
                                                        message.id !==
                                                        this.senderEmail
                                                }"
                                            >
                                                <ng-container
                                                    *ngTemplateOutlet="
                                                        speechBubbleExtension
                                                    "
                                                >
                                                </ng-container>
                                            </div>
                                        </ng-container>

                                        <div
                                            class="min-w-4 leading-5 text-md overflowText"
                                            [innerHTML]="message.message"
                                        ></div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <div
                        class="flex items-end absolute bottom-0 w-80 p-4 border-t bg-gray-50 dark:bg-transparent"
                    >
                        <mat-form-field
                            class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded fuse-mat-bold w-full"
                        >
                            <textarea
                                class="min-h-5 my-0 resize-none max-h-5"
                                style="
                                    margin: 11px 0 !important;
                                    padding: 0 !important;
                                "
                                [rows]="1"
                                matInput
                                [(ngModel)]="textValue"
                                (click)="removeNotification()"
                                #messageInput
                            ></textarea>
                        </mat-form-field>
                        <div class="flex items-center h-11 my-px ml-4">
                            <button
                                mat-icon-button
                                type="submit"
                                (click)="sendMessage(textValue)"
                            >
                                <mat-icon
                                    class="rotate-90"
                                    [svgIcon]="
                                        'heroicons_outline:paper-airplane'
                                    "
                                ></mat-icon>
                            </button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #selectChatOrStartNew>
    <div
        class="flex flex-col flex-auto items-center justify-center w-full h-full p-4"
    >
        <mat-icon
            class="icon-size-24"
            [svgIcon]="'heroicons_outline:chat'"
        ></mat-icon>
        <div
            class="mt-4 text-xl text-center font-medium tracking-tight text-secondary"
        >
            Select a conversation
        </div>
    </div>
</ng-template>


<ng-template #speechBubbleExtension>
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <path
                d="M1.01522827,0.516204834 C-8.83532715,54.3062744 61.7609863,70.5215302 64.8009949,64.3061218 C68.8074951,54.8859711 30.1663208,52.9997559 37.5036011,0.516204834 L1.01522827,0.516204834 Z"
                fill="currentColor"
                fill-rule="nonzero"
            ></path>
        </g>
    </svg>
</ng-template>
