import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseValidators } from '@fuse/validators';
import { InventoryService } from 'app/modules/admin/ecommerce/inventory/inventory.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';

interface PasswordChangeResponse {
  success: boolean;
  message: string;
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  ChangePassword: FormGroup;
  ChangeCurrr: boolean = false;
  passwordLengthError: boolean = false;
  isLoading: boolean = false;

  constructor(
    private _formBuilder: FormBuilder, 
    private _inventoryService: InventoryService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<ChangePasswordComponent>
  ) { }


  TempEmail = localStorage.getItem('TempEmail');

  ngOnInit(): void {
    this.ChangePassword = this._formBuilder.group({
        email: [this.TempEmail || '', [Validators.required, Validators.email]], 
        Newpassword: ['', Validators.required],
      passwordConfirm: ['', Validators.required]
    },
    {
      validators: FuseValidators.mustMatch('Newpassword', 'passwordConfirm')
    });
  }

  OnChangePassword() {
    if (this.ChangePassword.valid && !this.isLoading) {
      this.isLoading = true;
      
      const payload = {
        email: this.ChangePassword.value.email,
        password: this.ChangePassword.value.Newpassword
      };

      this._inventoryService.updateUserPassword(payload.email, payload.password)
        .subscribe({
          next: (response: PasswordChangeResponse) => {
            if (response.success) {
              // Show success message
            //   this._snackBar.open(response.message, 'Close', {
            //     duration: 3000,
            //     panelClass: ['success-snackbar']
            //   });
              
              // Reset form
              this.ChangePassword.reset();
              
              // Close dialog
              this.dialogRef.close(true);
            } else {
            //   this._snackBar.open('Failed to change password', 'Close', {
            //     duration: 3000,
            //     panelClass: ['error-snackbar']
            //   });
            }
          },
          error: (error) => {
            console.error('Error updating password:', error);
            // this._snackBar.open('Error changing password. Please try again.', 'Close', {
            //   duration: 3000,
            //   panelClass: ['error-snackbar']
            // });
          },
          complete: () => {
            this.isLoading = false;
          }
        });
    }
  }

  valuechange(passwordStr: any) {
    if (passwordStr.target.value) {
      if (!this.validatePassword(passwordStr.target.value)) {
        this.passwordLengthError = true;
      } else {
        this.passwordLengthError = false;
      }
    }

    if (!passwordStr.target.value) {
      this.passwordLengthError = false;
    }
  }

  validatePassword(password: string): boolean {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&=,./;:'"#^`~><|{}+)(_-])[A-Za-z\d@$!%*?&=,./;:`~'"#^><|{}+)(_-]{8,15}$/;
    return passwordPattern.test(password);
  }
}