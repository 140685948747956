/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {
    BehaviorSubject,
    catchError,
    filter,
    map,
    Observable,
    of,
    switchMap,
    take,
    tap,
    throwError,
} from 'rxjs';
import {
    InventoryBrand,
    InventoryCategory,
    InventoryPagination,
    InventoryProduct,
    InventoryTag,
    InventoryVendor,
} from 'app/modules/admin/ecommerce/inventory/inventory.types';

@Injectable({
    providedIn: 'root',
})
export class InventoryCampService {
    // Private
    private _brands: BehaviorSubject<InventoryBrand[] | null> =
        new BehaviorSubject(null);

    private _categories: BehaviorSubject<InventoryCategory[] | null> =
        new BehaviorSubject(null);
    private _pagination: BehaviorSubject<InventoryPagination | null> =
        new BehaviorSubject(null);
    private _product: BehaviorSubject<InventoryProduct | null> =
        new BehaviorSubject(null);
    private _products: BehaviorSubject<InventoryProduct[] | null> =
        new BehaviorSubject(null);
    private _tags: BehaviorSubject<InventoryTag[] | null> = new BehaviorSubject(
        null
    );
    private _vendors: BehaviorSubject<InventoryVendor[] | null> =
        new BehaviorSubject(null);
    private _subGroups: BehaviorSubject<any[] | null> = new BehaviorSubject(
        null
    );
    public updateCampaignData = new BehaviorSubject(undefined);
    public updateTNData = new BehaviorSubject(undefined);
    public CampaignsAndTnAlert = new BehaviorSubject(undefined);
    public newTNalert = new BehaviorSubject(undefined);
    public DownloadCSVTN = new BehaviorSubject(undefined);

    public updateNewTrackingNnumberData = new BehaviorSubject(undefined);

    private currentFilterItems = [];


    apiurl = environment.apiUrl;

    fetchTrackingNumbers(groupId: string, page: number, rows: number, filterItems: any[]): Observable<any> {
        const payload = {
            groupId,
            page,
            rows,
            filter_items: filterItems
        };

        // Get the token from local storage
        const token = localStorage.getItem('token');
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}` // Add the bearer token here
        });

        return this._httpClient.post(`${this.apiurl}number/get`, payload, { headers });
    }
    getfiltersData(groupId: string, filtername: string, page?: number, rows?: number, query?: string) {
        const token = localStorage.getItem('token');

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}` // Add the bearer token here
        });

        // Use HttpParams for cleaner query parameter handling
        let params = new HttpParams()
            .set('groupId', groupId)
            .set('filter', filtername);

        if (page !== undefined) {
            params = params.set('page', page.toString());
        }
        if (rows !== undefined) {
            params = params.set('rows', rows.toString());
        }
        if (query !== undefined) {
            params = params.set('query', query);
        }

        return this._httpClient.get<any>(`${this.apiurl}number/tracking/filter/list`, { headers, params })
            .pipe(
                catchError(error => {
                    console.error('Error fetching filter data:', error);
                    return throwError(error);
                })
            );
    }


    getSingleTrackingData(trackinId: any){
        const token = localStorage.getItem('token');
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}` // Add the bearer token here
        });
        return this._httpClient.get<any>(`${this.apiurl}number/get?trackingId=${trackinId}`,{ headers });
    }
    getdataforTeackingNumberdownload(): Observable<any> {
        return this._httpClient.get<any>(`${this.apiurl}number/get`);
      }

    setCurrentFilterItems(filters: any[]): void {
        this.currentFilterItems = filters;
    }

    getCurrentFilterItems(): any[] {
        return this.currentFilterItems;
    }
    private groupAndUserUri =
        'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/getgroup';
    private _data: any;
    MainGroupName: any;
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    //Post Audio
    postAudio(file) {
        const token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ Authorization: `Bearer ${token}` });


        const httpOptions = {
            headers: headers_object,
        };

        return this._httpClient.post(
            `${this.apiurl}number/ivrPrompts`,
            file,
            httpOptions
        );
    }


    saveAudioFileKey(fileKey: string) {
        const token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ Authorization: `Bearer ${token}` });

        const httpOptions = {
            headers: headers_object,
        };

        const payload = { key: fileKey }; // Structure the payload as needed
        return this._httpClient.post(
            `${this.apiurl}number/ivrPrompts`, // Replace with your actual endpoint
            payload,
            httpOptions
        );
    }




            getAudioFileList(page: number = 1, limit: number = 100) {
                const token = localStorage.getItem('token');
                const headers_object = new HttpHeaders({ Authorization: `Bearer ${token}` });


                const httpOptions = {
                    headers: headers_object,
                };

                return this._httpClient.get(
                    `${this.apiurl}number/ivrPrompts?page=${page}&limit=${limit}`,
                    httpOptions
                ).pipe(
                    map((response: any) => response.data.result) // Extract the result array
                );
            }


            getAudioUrl(key) {
                const token = localStorage.getItem('token');
                const headers_object = new HttpHeaders({ Authorization: `Bearer ${token}` });

                const httpOptions = {
                    headers: headers_object,
                };
                return this._httpClient.post(
                    `${this.apiurl}number/ivrPrompts`,
                    key,
                    httpOptions
                );
            }







     groupId = localStorage.getItem('Group')
    getdatafordownload(): Observable<any> {
        return this._httpClient.get<any>(`${this.apiurl}call/log/list?groupId=${this.groupId}`);
      }

      getTrackingNumberListDownload(Page: number, Row: number, groupId: string, filter_items: any[]) {
        const token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ Authorization: `Bearer ${token}` });

        const httpOptions = {
            headers: headers_object,
        };

        // Prepare the request body
        const requestBody: any = {
            page: Page,
            rows: Row,
            groupId: groupId,
            filter_items: filter_items,
        };


        return this._httpClient.post(
           `${this.apiurl}number/get`,
            requestBody,
            httpOptions
        );
    }




    //delete Audio
    deleteAudioFile(key) {
        const token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ token: token });

        const httpOptions = {
            headers: headers_object,
        };
        return this._httpClient.post(
            'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/deleteAudio',
            { objectKey: key },
            httpOptions
        );
    }

    //get trackingList all
// Service method to get tracking list
getNewTrackingNumberList(Page: number, Row: number, groupId: string, filter_items: any[]) {
    const token = localStorage.getItem('token');
    const headers_object = new HttpHeaders({ Authorization: `Bearer ${token}` });

    const httpOptions = {
        headers: headers_object,
    };

    // Prepare the request body
    const requestBody: any = {
        page: Page,
        rows: Row,
        groupId: groupId,
        filter_items: filter_items,
    };


    return this._httpClient.post(
        `${this.apiurl}number/get`,
        requestBody,
        httpOptions
    );
}



    ////////////////////////////////////////////////////////////////

    // getAPI(formdata) {
    //
    //     let auth = "bri190723#735";

    //     var headers_object = new HttpHeaders({Authorization: auth, AccessToken : 'key' });
    //
    //     const httpOptions = {
    //         headers: headers_object,
    //     };
    //     return this._httpClient.post(
    //         'https://api.britewireless.com/api/sign-on',
    //         {"identifier":"rvlirshad@gmail.com","password":"12345678"},

    //         httpOptions
    //     );
    // }

    //get campaign List
    getNewCampaignList() {
        const token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ token: token });

        const httpOptions = {
            headers: headers_object,
        };
        return this._httpClient.get(
            'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/getCampaignsByUserEmail',
            httpOptions
        );
    }

    //check external number exists or not
    //Post Audio
    checkAvailability(number) {
        const token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ token: token });

        const httpOptions = {
            headers: headers_object,
        };
        return this._httpClient.post(
            'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/checkExtension',
            {
                extension: number,
            },
            httpOptions
        );
    }
    /**
     * Getter for brands
     */
    get brands$(): Observable<InventoryBrand[]> {
        return this._brands.asObservable();
    }

    /**
     * Getter for categories
     */
    get categories$(): Observable<InventoryCategory[]> {
        return this._categories.asObservable();
    }

    /**
     * Getter for pagination
     */
    get pagination$(): Observable<InventoryPagination> {
        return this._pagination.asObservable();
    }

    /**
     * Getter for product
     */
    get product$(): Observable<InventoryProduct> {
        return this._product.asObservable();
    }

    /**
     * Getter for products
     */
    get products$(): Observable<InventoryProduct[]> {
        return this._products.asObservable();
    }

    get subGroups$(): Observable<any> {
        return this._subGroups.asObservable();
    }

    /**
     * Getter for tags
     */
    get tags$(): Observable<InventoryTag[]> {
        return this._tags.asObservable();
    }

    /**
     * Getter for vendors
     */
    get vendors$(): Observable<InventoryVendor[]> {
        return this._vendors.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get brands
     */
    getBrands(): Observable<InventoryBrand[]> {
        return this._httpClient
            .get<InventoryBrand[]>('api/apps/ecommerce/inventory/brands')
            .pipe(
                tap((brands) => {
                    this._brands.next(brands);
                })
            );
    }

    /**
     * Get categories
     */
    getCategories(): Observable<InventoryCategory[]> {
        return this._httpClient
            .get<InventoryCategory[]>('api/apps/ecommerce/inventory/categories')
            .pipe(
                tap((categories) => {
                    this._categories.next(categories);
                })
            );
    }

    /**
     * Get products
     *
     *
     * @param page
     * @param size
     * @param sort
     * @param order
     * @param search
     */
    getProducts(
        page: number = 0,
        size: number = 10,
        sort: string = 'name',
        order: 'asc' | 'desc' | '' = 'asc',
        search: string = ''
    ): Observable<{
        pagination: InventoryPagination;
        products: InventoryProduct[];
    }> {
        return this._httpClient
            .get<{
                pagination: InventoryPagination;
                products: InventoryProduct[];
            }>('api/apps/ecommerce/inventory/products', {
                params: {
                    page: '' + page,
                    size: '' + size,
                    sort,
                    order,
                    search,
                },
            })
            .pipe(
                tap((response) => {
                    this._pagination.next(response.pagination);
                    this._products.next(response.products);
                })
            );
    }

    /**
     * Get product by id
     */
    getProductById(id: string): Observable<InventoryProduct> {
        return this._products.pipe(
            take(1),
            map((products) => {
                // Find the product
                const product = products.find((item) => item.id === id) || null;

                // Update the product
                this._product.next(product);

                // Return the product
                return product;
            }),
            switchMap((product) => {
                if (!product) {
                    return throwError(
                        'Could not found product with id of ' + id + '!'
                    );
                }

                return of(product);
            })
        );
    }

    /**
     * Create product
     */
    createProduct(): Observable<InventoryProduct> {
        return this.products$.pipe(
            take(1),
            switchMap((products) =>
                this._httpClient
                    .post<InventoryProduct>(
                        'api/apps/ecommerce/inventory/product',
                        {}
                    )
                    .pipe(
                        map((newProduct) => {
                            // Update the products with the new product
                            this._products.next([newProduct, ...products]);

                            // Return the new product
                            return newProduct;
                        })
                    )
            )
        );
    }

    /**
     * Update product
     *
     * @param id
     * @param product
     */
    updateProduct(
        id: string,
        product: InventoryProduct
    ): Observable<InventoryProduct> {
        return this.products$.pipe(
            take(1),
            switchMap((products) =>
                this._httpClient
                    .patch<InventoryProduct>(
                        'api/apps/ecommerce/inventory/product',
                        {
                            id,
                            product,
                        }
                    )
                    .pipe(
                        map((updatedProduct) => {
                            // Find the index of the updated product
                            const index = products.findIndex(
                                (item) => item.id === id
                            );

                            // Update the product
                            products[index] = updatedProduct;

                            // Update the products
                            this._products.next(products);

                            // Return the updated product
                            return updatedProduct;
                        }),
                        switchMap((updatedProduct) =>
                            this.product$.pipe(
                                take(1),
                                filter((item) => item && item.id === id),
                                tap(() => {
                                    // Update the product if it's selected
                                    this._product.next(updatedProduct);

                                    // Return the updated product
                                    return updatedProduct;
                                })
                            )
                        )
                    )
            )
        );
    }

    /**
     * Delete the product
     *
     * @param id
     */
    deleteProduct(id: string): Observable<boolean> {
        return this.products$.pipe(
            take(1),
            switchMap((products) =>
                this._httpClient
                    .delete('api/apps/ecommerce/inventory/product', {
                        params: { id },
                    })
                    .pipe(
                        map((isDeleted: boolean) => {
                            // Find the index of the deleted product
                            const index = products.findIndex(
                                (item) => item.id === id
                            );
                            // Delete the product
                            products.splice(index, 1);
                            // Update the products
                            this._products.next(products);
                            // Return the deleted status
                            return isDeleted;
                        })
                    )
            )
        );
    }

    /**
     * Get tags
     */
    getTags(): Observable<InventoryTag[]> {
        return this._httpClient
            .get<InventoryTag[]>('api/apps/ecommerce/inventory/tags')
            .pipe(
                tap((tags) => {
                    this._tags.next(tags);
                })
            );
    }

    /**
     * Create tag
     *
     * @param tag
     */
    createTag(tag: InventoryTag): Observable<InventoryTag> {
        return this.tags$.pipe(
            take(1),
            switchMap((tags) =>
                this._httpClient
                    .post<InventoryTag>('api/apps/ecommerce/inventory/tag', {
                        tag,
                    })
                    .pipe(
                        map((newTag) => {
                            // Update the tags with the new tag
                            this._tags.next([...tags, newTag]);

                            // Return new tag from observable
                            return newTag;
                        })
                    )
            )
        );
    }

    /**
     * Update the tag
     *
     * @param id
     * @param tag
     */
    updateTag(id: string, tag: InventoryTag): Observable<InventoryTag> {
        return this.tags$.pipe(
            take(1),
            switchMap((tags) =>
                this._httpClient
                    .patch<InventoryTag>('api/apps/ecommerce/inventory/tag', {
                        id,
                        tag,
                    })
                    .pipe(
                        map((updatedTag) => {
                            // Find the index of the updated tag
                            const index = tags.findIndex(
                                (item) => item.id === id
                            );

                            // Update the tag
                            tags[index] = updatedTag;

                            // Update the tags
                            this._tags.next(tags);

                            // Return the updated tag
                            return updatedTag;
                        })
                    )
            )
        );
    }

    /**
     * Delete the tag
     *
     * @param id
     */
    deleteTag(id: string): Observable<boolean> {
        return this.tags$.pipe(
            take(1),
            switchMap((tags) =>
                this._httpClient
                    .delete('api/apps/ecommerce/inventory/tag', {
                        params: { id },
                    })
                    .pipe(
                        map((isDeleted: boolean) => {
                            // Find the index of the deleted tag
                            const index = tags.findIndex(
                                (item) => item.id === id
                            );

                            // Delete the tag
                            tags.splice(index, 1);

                            // Update the tags
                            this._tags.next(tags);

                            // Return the deleted status
                            return isDeleted;
                        }),
                        filter((isDeleted) => isDeleted),
                        switchMap((isDeleted) =>
                            this.products$.pipe(
                                take(1),
                                map((products) => {
                                    // Iterate through the contacts
                                    products.forEach((product) => {
                                        const tagIndex = product.tags.findIndex(
                                            (tag) => tag === id
                                        );

                                        // If the contact has the tag, remove it
                                        if (tagIndex > -1) {
                                            product.tags.splice(tagIndex, 1);
                                        }
                                    });

                                    // Return the deleted status
                                    return isDeleted;
                                })
                            )
                        )
                    )
            )
        );
    }

    /**
     * Get vendors
     */
    getVendors(): Observable<InventoryVendor[]> {
        return this._httpClient
            .get<InventoryVendor[]>('api/apps/ecommerce/inventory/vendors')
            .pipe(
                tap((vendors) => {
                    this._vendors.next(vendors);
                })
            );
    }

    // getGroupAndUser(): Observable<any>{
    //     const token = localStorage.getItem('token');
    //     const headers_object = new HttpHeaders({'token': token});
    //     const staticBodyObj = {
    //       "name": "BMW-1"
    //   }

    //     const httpOptions = {
    //         headers: headers_object
    //       };

    //     return this._httpClient.post(this.groupAndUserUri,staticBodyObj,httpOptions);
    //   }
    Variable = new BehaviorSubject(undefined);
    UserVariable = new BehaviorSubject(undefined);
    SubVariable = new BehaviorSubject<any>({});
    TrackingNum = new BehaviorSubject(undefined);
    AllTrackingNum = new BehaviorSubject<any>({length: 0});
    CallLogsVariable = new BehaviorSubject(undefined);
    SubUserVariable = new BehaviorSubject(undefined);
    GetSelectedGroupname = new BehaviorSubject(undefined);
    UpdateForUser: any;
    UpdateForGroup: any;
    TreeSlectedId: any;
    DefaultId: any;
    private messageSource = new BehaviorSubject(undefined);
    currentMessage = this.messageSource.asObservable();
    NEWID = new BehaviorSubject(undefined);
    DeleteTN = new BehaviorSubject(undefined);
    CampaignIDD = new BehaviorSubject(undefined);
    EditTracking = new BehaviorSubject(undefined);
    TrackingNumberLength = new BehaviorSubject(undefined);


    changeMessage(message: string) {
        this.messageSource.next(message);
    }
    private TableSource = new BehaviorSubject(undefined);
    currentTableSource = this.TableSource.asObservable();
    TableMessage(message: any) {
        this.TableSource.next(message);
    }
    getGroupssssAndUser(ouid: any): Observable<{ subgoroups: [] }> {
        const token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);

        return this._httpClient.post<{ subgoroups: any }>(
            this.groupAndUserUri,
            { ouid },
            { headers }
        );
    }

    getAllOwners(): Observable<{}> {
        const token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ token: token });

        const httpOptions = {
            headers: headers_object,
        };

        return this._httpClient.get(
            'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/getOwners',
            httpOptions
        );
        // .pipe(
        // tap((response:any) => {

        //     this._subGroups.next(response.body.groups ? response.body.groups : []);
        //     if(response.body.groups){
        //         this.makePagination(response.body.groups)
        //     }
        // })
        // );
    }
    getAllSources(): Observable<{}> {
        const token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ token: token });
        const httpOptions = {
            headers: headers_object,
        };
        return this._httpClient.get(
            'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/getAdSources',
            httpOptions
        );
    }
    getAllCampaignList(Page, Row, groupId?): Observable<{}> {
        const token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ Authorization: `Bearer ${token}` });
      


        const httpOptions = {
            headers: headers_object,
        };

        return this._httpClient.post(
            `${this.apiurl}groups/getGroup`,
            {
                page: Page,
                rows: Row,
                groupId: groupId,
                filter_items: []
            },
            httpOptions
        );
    }
    getAllTrackingList(campaignId) {
        const token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ token: token });
        const httpOptions = {
            headers: headers_object,
        };
        return this._httpClient.post(
            'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/getTrackingNumbers',
            { campaignId: campaignId },
            httpOptions
        );
    }





    getAllTrackingNumbers(campaignId: any, Page: number, Row: number, groupId?: string, filter_items?: any[]) {
        const token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ Authorization: `Bearer ${token}` }); // Use Bearer for authorization

        const httpOptions = {
            headers: headers_object,
        };

        return this._httpClient.post(
            `${this.apiurl}number/get`,
            {
                campaignId,
                page: Page,
                rows: Row,
                groupId: groupId,
                filter_items: filter_items || []
            },
            httpOptions
        );
    }

    DeleteTrackingNumbers(trackingId: any) {
        const token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ Authorization: `Bearer ${token}` }); // Use Bearer for authorization
        const httpOptions = {
            headers: headers_object,
        };

        // Use DELETE method instead of POST
        return this._httpClient.delete(
            `${this.apiurl}number/provision?trackingId=${trackingId}`,
            { body: { trackingId }, ...httpOptions } // Include the trackingId in the body
        );
    }


    deleteCampaingn(campaignId) {
        const token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ token: token });
        const httpOptions = {
            headers: headers_object,
        };
        return this._httpClient.post(
            'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/deleteCampaign',
            { campaignId: campaignId },
            httpOptions
        );
    }

    makePagination(dataArray) {
        let paginationValues: any;

        const search = 'search';
        const sort: any = 'groupName';
        const order = 'asc';
        const page = 0;
        const size = 10;

        // Clone the products
        let products: any[] | null = dataArray;

        // Sort the products
        if (
            sort === 'sku' ||
            sort === 'groupName' ||
            sort === 'active' ||
            sort === 'externalId' ||
            sort === 'industry'
        ) {
            products.sort((a, b) => {
                const fieldA = a[sort].toString().toUpperCase();
                const fieldB = b[sort].toString().toUpperCase();
                return order === 'asc'
                    ? fieldA.localeCompare(fieldB)
                    : fieldB.localeCompare(fieldA);
            });
        } else {
            products.sort((a, b) =>
                order === 'asc' ? a[sort] - b[sort] : b[sort] - a[sort]
            );
        }

        // If search exists...
        if (search) {
            // Filter the products
            products = products.filter(
                (contact) =>
                    contact.groupName &&
                    contact.groupName
                        .toLowerCase()
                        .includes(search.toLowerCase())
            );
        }

        // Paginate - Start
        const productsLength = products.length;

        // Calculate pagination details
        const begin = page * size;
        const end = Math.min(size * (page + 1), productsLength);
        const lastPage = Math.max(Math.ceil(productsLength / size), 1);

        // Prepare the pagination object
        let pagination = {};

        // If the requested page number is bigger than
        // the last possible page number, return null for
        // products but also send the last possible page so
        // the app can navigate to there
        if (page > lastPage) {
            products = null;
            pagination = {
                lastPage,
            };
        } else {
            // Paginate the results by size
            products = products.slice(begin, end);

            // Prepare the pagination mock-api
            pagination = {
                length: productsLength,
                size: size,
                page: page,
                lastPage: lastPage,
                startIndex: begin,
                endIndex: end - 1,
            };
        }
    }

    updateTrackingNumbers(payload: any) {
        const token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

        // console.log('Service Payload:', JSON.stringify(payload, null, 2));

        return this._httpClient.put(

          `${this.apiurl}number/provision`,
          payload,
          { headers }
        ).pipe(
          catchError(error => {
            console.error('Error in updateTrackingNumbers:', error);
            console.error('Error response:', error.error);
            return throwError(error);
          })
        );
    }
    createCampaign(campainDetails: any) {
        const token = localStorage.getItem('token');
        // token ='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InNhbmNoZXo1QHlvcG1haWwuY29tIiwiaWF0IjoxNjYwNTcyNDA1LCJleHAiOjE2NjA2NTg4MDV9.dWAF8w2k5Y54SQugseSlPq0YrvXMBozpU2dmES6brPI'
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/createCampaign',
            campainDetails,
            { headers }
        );
    }
    UpdateCampaign(campaignDetails: any, id: any) {
        const token = localStorage.getItem('token');
        // token ='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InNhbmNoZXo1QHlvcG1haWwuY29tIiwiaWF0IjoxNjYwNTcyNDA1LCJleHAiOjE2NjA2NTg4MDV9.dWAF8w2k5Y54SQugseSlPq0YrvXMBozpU2dmES6brPI'
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/editCampaign',
            { campaignDetails, id },
            { headers }
        );
    }
    AddTrackingNumbers(payload: any) {
        const token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

        // Debug: Log the payload being sent
        console.log('Payload:', payload);

        return this._httpClient.post(


           `${this.apiurl}number/provision`, payload,
            { headers }
        );
    }

    createGroup(groupDetails: any, parentId: any) {
        const token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/creategroup',
            { groupDetails, parentId },
            { headers }
        );
    }
    UpdateGroup(groupDetails: any) {
        const token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/editgroup',
            groupDetails,
            { headers }
        );
    }
    UpdateUser(userDetails: any) {
        const token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/updateuser',
            userDetails,
            { headers }
        );
    }
    DeleteUser(ouid: any) {
        const token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/deleteuser',
            ouid,
            { headers }
        );
    }
    DeleteGroup(ouid: any) {
        let token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/deletegroup',
            ouid,
            { headers }
        );
    }
    GetParentName(ouid: number) {
        const token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/getParentName',
            { ouid },
            { headers }
        );
    }

    GetFilterGroupCall(id: any, filter_items: any = []) {
        const tableName = 'callLogs';
        const token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/filter',
            { tableName, id, filter_items },
            { headers }
        );
    }
    GetFilterGroupCallAllTN(id: any, filter_items: any = []) {
        const tableName = 'trackingNumbers';
        const token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/filter',
            { tableName, id, filter_items },
            { headers }
        );
    }

    getfiltergrouptrackingnumber(id: any, Page: number, Row: number, filter_items: any = []) {
        const tableName = 'trackingNumbers';
        const token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

        const payload = {
            groupId: id, // Assuming 'id' is the group ID
            page: Page,
            rows: Row,
            filter_items: filter_items // Pass the filter items directly
        };

        return this._httpClient.post(
            `${this.apiurl}number/get`,
            payload,
            { headers }
        );
    }




    GetPermissions(ouid: any) {
        const token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/permissions',
            { ouid },
            { headers }
        );
    }
    UpdatePermissions(data: any = []) {
        const token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/changePermissions',
            { data },
            { headers }
        );
    }
    // GetExtensions() {
    //     return this._httpClient.get(
    //         'https://fusion.senarios.co/fusionpbx/app/destinations/getActions.php'
    //     );
    // }
    // AddDestination(obj:any){
    //     const formData = new FormData();
    //     formData.append('destination_type', 'inbound');
    //     formData.append('destination_number', '+13213422193')
    //     formData.append('destination_context', 'public')
    //     formData.append('destination_cid_name_prefix', '')
    //     formData.append('destination_accountcode', '')
    //     formData.append('domain_uuid', '76966341-7249-4fc3-98db-c64af81bc376')
    //     formData.append('destination_enabled', obj.destination_enabled)
    //     formData.append('destination_description', '')
    //     formData.append('action', obj.action)
    //     return this._httpClient.post('https://fusion.senarios.co/fusionpbx/app/destinations/addrava.php', formData)
    // }
    GetFilterUserCall(filter_items: any = []) {
        // const groupId = localStorage.getItem('Group');
        let ouid = localStorage.getItem('TreeID');
        const tableName = 'campaigns';
        const id = ouid;
        const token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/filter',
            { tableName, id, filter_items },
            { headers }
        );
    }

    getGroupById(id) {
        const token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ token: token });
        const staticBodyObj = {
            name: 'BMW-1',
        };

        const httpOptions = {
            headers: headers_object,
        };
        return this._httpClient.get(
            'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/creategroup/' +
                id,
            httpOptions
        );
    }
    updateUserPassword(oldPassword: any, newPassword: any, ouid: any) {
        const token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            ' https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/changePassword',
            { oldPassword, newPassword, ouid },
            { headers }
        );
    }
    /**
     * Update the avatar of the given contact
     *
     * @param id
     * @param avatar
     */
    /*uploadAvatar(id: string, avatar: File): Observable<Contact>
    {
        return this.contacts$.pipe(
            take(1),
            switchMap(contacts => this._httpClient.post<Contact>('api/apps/contacts/avatar', {
                id,
                avatar
            }, {
                headers: {
                    'Content-Type': avatar.type
                }
            }).pipe(
                map((updatedContact) => {

                    // Find the index of the updated contact
                    const index = contacts.findIndex(item => item.id === id);

                    // Update the contact
                    contacts[index] = updatedContact;

                    // Update the contacts
                    this._contacts.next(contacts);

                    // Return the updated contact
                    return updatedContact;
                }),
                switchMap(updatedContact => this.contact$.pipe(
                    take(1),
                    filter(item => item && item.id === id),
                    tap(() => {

                        // Update the contact if it's selected
                        this._contact.next(updatedContact);

                        // Return the updated contact
                        return updatedContact;
                    })
                ))
            ))
        );
    }*/
}
