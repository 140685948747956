<div [class.none]="isSearching" *ngIf="!isLoading" class="maingroup">
    <div class="flex items-center pt-2 px-8 md:px-8">
        <p *ngIf="showGroupsTrees" class="mr-1" style="color: #1E293B; margin: 0;">Selected Groups</p>

        <div *ngIf="showGroupsTrees" (click)="openGroupsModal()" class="breadCrumbs mainact inline-flex">
            <div class="maintext" *ngIf="groupSelectionType === 'single'">
                <span class="text fontcss">{{ level1Item }}</span>
                <span *ngIf="level2Item.length > 0"> > </span>
                <span *ngIf="level2Item" class="text fontcss">{{ level2Item }}</span>
                <span *ngIf="level3Item.length > 0"> > </span>
                <span class="text fontcss" *ngIf="level3Item">{{ level3Item }}</span>
            </div>

            <div class="maintext" *ngIf="groupSelectionType === 'multiple'">
                <span class="text">{{ level1Item }}</span>
                <span *ngIf="level2Item.length > 0">
                    <span class="signature"> > </span>
                    <span class="fontcss">{{ level2Item }}</span>
                    <span class="counter">{{ countLevel2 }}</span>
                </span>
                <span *ngIf="level3Item.length > 0">
                    <span class="signature"> > </span>
                    <span class="fontcss">{{ level3Item }}</span>
                    <span class="counter">{{ countLevel3 }}</span>
                </span>
            </div>
        </div>
    </div>
</div>
