<h1 mat-dialog-title>Select Groups</h1>
<div mat-dialog-content>
	<div #treeContainer class="treeContainer">
		<input class="groupFilterSearch" [value]="currentFilterText" placeholder="Search groups" (input)="filterChanged($event.target.value)" />

		<cdk-virtual-scroll-viewport itemSize="38" minBufferPx="500" maxBufferPx="900" class="virtual-viewport">
			<ng-container *cdkVirtualFor="let node of dataSource">
				<div class="tree-node" [style.padding-left]="node.level * 24 + 'px'" [style.display]="showNode(node) ? 'flex' : 'none'">
					<!-- Disabled button padding for leaf nodes -->
					<button mat-icon-button disabled *ngIf="!node.expandable"></button>
					<!-- Expand/Collapse button for parent nodes-->
					<button mat-icon-button (click)="treeControl.toggle(node)" *ngIf="node.expandable">
						<mat-icon class="mat-icon-rtl-mirror">
							{{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
						</mat-icon>
					</button>
					<mat-checkbox
						class="checklist-leaf-node"
						[checked]="checklistSelection.isSelected(node.id)"
						(click)="selectionToggle(node)"
						[indeterminate]="descendantsPartiallySelected(node)"
						color="primary"
						[disabled]="node.isDisabled"
					>
						{{ node.item }}
					</mat-checkbox>
				</div>
			</ng-container>
		</cdk-virtual-scroll-viewport>
		<!-- <div [formGroup]="groupTreeDetailsForm" *ngIf="dialog_data.page && dialog_data.page == 'insights'" class="date-picker">
			<label class="llm-label">
				Start date for added groups
				<span class="required-star">*</span>
			</label>
			<mat-form-field appearance="none" class="start-container">
				<input
					matInput
					[matDatepicker]="startPicker"
					type="text"
					class="group_start_date"
					formControlName="start_date"
					(dateChange)="onDateChange()"
					[max]="maxDate"
					(click)="openStartPicker(); startPicker.open()"
					readonly
				/>
				<mat-datepicker-toggle matSuffix [for]="startPicker" (click)="openStartPicker(); startPicker.open()">
					<mat-icon matDatepickerToggleIcon svgIcon="campiagn-calendar"></mat-icon>
				</mat-datepicker-toggle>

				<mat-datepicker #startPicker>
					<mat-datepicker-actions class="action_datepicker">
						<div class="time_setting">
							<div>
								<input
									type="text"
									class="time"
									formControlName="start_hours"
									pattern="((0[0-9])|(1[0-2]))"
									onkeypress="return event.charCode >= 48 && event.charCode <= 58"
									maxlength="2"
									min="0"
									max="12"
									onclick="this.select()"
								/>
								<span class="time_space">:</span>
								<input
									type="text"
									class="time"
									formControlName="start_minutes"
									pattern="([0-5][0-9])"
									onkeypress="return event.charCode >= 48 && event.charCode <= 58"
									maxlength="2"
									onclick="this.select()"
								/>
								<mat-button-toggle-group formControlName="start_meridiem">
									<mat-button-toggle value="AM">AM</mat-button-toggle>
									<mat-button-toggle value="PM">PM</mat-button-toggle>
								</mat-button-toggle-group>
							</div>
						</div>
						<div mat-dialog-actions class="calendar-modal-actions">
							<button class="datepicker-close-btn" (click)="onStartPickerClear(startPicker)">Clear</button>
							<button
								class="datepicker-apply-btn"
								(click)="startPicker.close()"
								[disabled]="false"
								color="primary"
								[disabled]="groupTreeDetailsForm.get('start_hours').invalid || groupTreeDetailsForm.get('start_minutes').invalid"
								matDatepickerApply
							>
								Apply
							</button>
						</div>
					</mat-datepicker-actions>
				</mat-datepicker>
			</mat-form-field>
			<mat-error style="font-size: 11px" *ngIf="groupTreeDetailsForm.get('start_date').hasError('required')"> Please enter start date </mat-error>
			<mat-error style="font-size: 11px" *ngIf="startDateError"> Start date must be greater than current date </mat-error>
		</div> -->

		<!-- <div *ngIf="dialog_data.page && dialog_data.page == 'insights' && dialog_data?.insight_type == 'keyword'">
			<mat-checkbox class="cs_checkbox" [disableRipple]="true" [checked]="LLM_confirmation_check" (click)="LLM_confirmation_check = !LLM_confirmation_check">
				<span class="llm-label">I understand there are charges associated with use of keyword.</span>
			</mat-checkbox>
		</div> -->
	</div>
</div>

<div mat-dialog-actions style="padding: 0px 29px; justify-content: end">
	<button class="close-btn" mat-button mat-dialog-close>Close</button>
	<button

		class="fuse-mat-button"
        mat-flat-button

		mat-dialog-close
		(click)="closeModal()"
		[disabled]="!selection_changed"
		color="primary"
	>
		Apply
	</button>
	<button *ngIf="dialog_data.insight_type == 'LLM'" class="save-add-to-queue-btn accent-btn" mat-button mat-dialog-close (click)="closeModal()" [disabled]="!selection_changed" color="primary">
		{{ isButtonDisabled() ? 'Save' : 'Save and add to queue' }}
	</button>
	<button
		*ngIf="dialog_data?.from_calibration"
        mat-flat-button
		class="fuse-mat-button-large w-6/12 mt-6 ml-auto mr-auto"
		mat-dialog-close
		(click)="closeModal()"
		[disabled]="this.checklistSelection.selected && this.checklistSelection.selected?.length == 0"
		color="primary"
	>
		Apply
	</button>
</div>
