/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, delay, map } from 'rxjs';
import { set, sub } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Filter } from '../shared/shared-filter/model/filter.model';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  headers: HttpHeaders;
  uploadHeaders: HttpHeaders;
  currentTimeZone: string = window.sessionStorage.timezone || 'utc';
  userTimeZone = new Subject<string>();
  userTimeZoneObsv$ = this.userTimeZone.asObservable().pipe(delay(100));
  clearFiltersSubj = new Subject<boolean>();
  isGroupChange = new BehaviorSubject<any>(false);

  ou_id;
  time_zone;
  constructor(private http: HttpClient) {
    this.userTimeZone.next(this.currentTimeZone);
  }

  getUserTokens() {
    const cfa_user_data = JSON.parse(localStorage.getItem('user'));
    this.ou_id = cfa_user_data['data']['main_group']['id'];
    this.time_zone = cfa_user_data['data']['user']['tzname'];

    const cfa_access_token = cfa_user_data['data']['user']['cfa_access_token'];
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `bearer ${cfa_access_token}`,
    });
    this.uploadHeaders = new HttpHeaders({
      Authorization: `bearer ${cfa_access_token}`,
    });
  }

  getFilterDataold(tableName, obj) {
    const TableName = 'campaigns';
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('token', token);
    return this.http.post(
        'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/filter',
        { tableName, obj },
        { headers }
    );
}


  getFilterData(filterName: {}): Observable<any> {
    // this.getUserTokens();

    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('token', token);
    return this.http.post( 'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/filter', { filterName }, { headers }).pipe(
      map((resp) => {
        // save filter values data to sessionStorage
        // sessionStorage.setItem(filterName, JSON.stringify(resp['json']));
        return resp['json'];
      }),
    );
  }

  getStartEndTimes(duration: number, time_unit: string = 'days', restoreSaved: boolean = true) {
    const saved_dates = localStorage.getItem('athena_dates') || '';
    if (restoreSaved && saved_dates && false) {
      // fetch saved dates
      const parsed_dates = JSON.parse(saved_dates);
      parsed_dates['start_time'] = new Date(parsed_dates['start_time']);
      parsed_dates['end_time'] = new Date(parsed_dates['end_time']);
      return parsed_dates;
    }
    if (time_unit === 'months') {
      let end_time = set(new Date(), { date: 1, hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
      end_time = zonedTimeToUtc(end_time, this.currentTimeZone);
      const start_time = sub(end_time, { months: duration });
      return { start_time, end_time };
    } else if (time_unit === 'today') {
      const end_time = new Date();
      let start_time = set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
      start_time = zonedTimeToUtc(start_time, this.currentTimeZone);
      return { start_time, end_time };
    } else {
      let end_time = set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
      end_time = zonedTimeToUtc(end_time, this.currentTimeZone);
      const start_time = sub(end_time, { days: duration });
      return { start_time, end_time };
    }
  }

  formatFilterValues(filter: Filter[]): Filter[] {
    const filter_data: Filter[] = JSON.parse(JSON.stringify(filter));
    const mapping = {
      Scored: 'scored',
      Unscored: 'unscored',
      Reviewed: 'reviewed',
      Partial: 'needs_scorecard',
      Active: 'active',
      Inactive: 'inactive',
      Deleted: 'deleted',
    };
    // format 'Score Status'
    const score_status_filter = filter_data.find((f) => f.filter_name === 'Score Status');
    if (score_status_filter) {
      score_status_filter.filter_items = score_status_filter.filter_items.map((item) => {
        item.value = mapping[item.value];
        return item;
      });
    }
    // format 'Scorecard Status'
    const scorecard_status_filter = filter_data.find((f) => f.filter_name === 'Scorecard Status');
    if (scorecard_status_filter) {
      scorecard_status_filter.filter_items = scorecard_status_filter.filter_items.map((item) => {
        item.value = mapping[item.value];
        return item;
      });
    }
    // format 'Scorecard Outcome'
    const outcome_filter = filter_data.find((f) => f.filter_name === 'Scorecard Outcome');
    if (outcome_filter) {
      outcome_filter.filter_items = outcome_filter.filter_items.map((item) => {
        item.value = item.value === 'Yes' ? 'true' : 'false';
        return item;
      });
    }
    return filter_data;
  }

  formatCustomSelectOptions(options, valueKey: string, viewValueKey: string, removeDuplicates: boolean) {
    // map into array of {value, viewValue} object
    let formatted_options = options.map((el, index) => ({
        value: valueKey ? el[valueKey].toString() : index.toString(),
        viewValue: el[viewValueKey],
      }));
    // remove duplicate 'viewValue'
    if (removeDuplicates) {formatted_options = formatted_options.filter((v, i, a) => a.findIndex(v2 => v2.viewValue === v.viewValue) === i);}
    // sort by 'viewValue'
    formatted_options = formatted_options.sort((a, b) => {
      if (a.viewValue.toUpperCase() < b.viewValue.toUpperCase()) {return -1;}
      if (a.viewValue.toUpperCase() > b.viewValue.toUpperCase()) {return 1;}
      return 0;
    });
    return formatted_options;
  }

  getAllTreeGroups(): Observable<{}> {
    const token = localStorage.getItem('token');
    const headers_object = new HttpHeaders({ token: token });

    const httpOptions = {
        headers: headers_object,
    };

    return this.http.get<{}>(
        'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/treeApi',
        httpOptions
    );
}

setGroupChange(value: boolean) {
    this.isGroupChange.next(value);
}

}
