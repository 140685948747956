<div class="flex flex-col flex-auto p-2 md:p-4">
    <div class="flex items-center justify-between">
        <div class="text-2xl font-semibold">Change Password</div>
        <button matDialogClose mat-icon-button>
            <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
</div>

<div class="modal-body">
    <form [formGroup]="ChangePassword">
        <!-- Email field -->
       
        <mat-form-field class="w-full">
            <mat-label>Email</mat-label>
            <input
                matInput
                type="email"
                [formControlName]="'email'"
                placeholder="Enter your email"
                readonly="true"
            />
            <mat-error *ngIf="ChangePassword.get('email').hasError('required')">
                Email is required
            </mat-error>
            <mat-error *ngIf="ChangePassword.get('email').hasError('email')">
                Please enter a valid email address
            </mat-error>
        </mat-form-field>

        <!-- New password field -->
        <mat-form-field class="w-full">
            <mat-label>New password</mat-label>
            <input
                id="password"
                matInput
                type="password"
                #passwordFieldone
                [formControlName]="'Newpassword'"
                (keyup)="valuechange($event)"
                placeholder="Please enter your password"
            />
            <button
                mat-icon-button
                type="button"
                (click)="passwordFieldone.type === 'password' ? (passwordFieldone.type = 'text') : (passwordFieldone.type = 'password')"
                matSuffix>
                <mat-icon
                    class="icon-size-5"
                    *ngIf="passwordFieldone.type === 'password'"
                    [svgIcon]="'heroicons_solid:eye-off'">
                </mat-icon>
                <mat-icon
                    class="icon-size-5"
                    *ngIf="passwordFieldone.type === 'text'"
                    [svgIcon]="'heroicons_solid:eye'">
                </mat-icon>
            </button>
            <mat-error *ngIf="ChangePassword.get('Newpassword').hasError('required')">
                Password is required
            </mat-error>
        </mat-form-field>

        <p *ngIf="passwordLengthError"
            style="font-size: 12px; font-weight: 500; margin-top: 0px; color: #b90101;">
            Password must contain min 8 characters(one uppercase, one lowercase, one special character)
        </p>

        <!-- Password confirm field -->
        <mat-form-field class="w-full">
            <mat-label>Retype password</mat-label>
            <input
                id="password-confirm"
                matInput
                type="password"
                [formControlName]="'passwordConfirm'"
                #passwordConfirmField
                placeholder="Please retype your password"
            />
            <button
                mat-icon-button
                type="button"
                (click)="passwordConfirmField.type === 'password' ? (passwordConfirmField.type = 'text') : (passwordConfirmField.type = 'password')"
                matSuffix>
                <mat-icon
                    class="icon-size-5"
                    *ngIf="passwordConfirmField.type === 'password'"
                    [svgIcon]="'heroicons_solid:eye-off'">
                </mat-icon>
                <mat-icon
                    class="icon-size-5"
                    *ngIf="passwordConfirmField.type === 'text'"
                    [svgIcon]="'heroicons_solid:eye'">
                </mat-icon>
            </button>
            <mat-error *ngIf="ChangePassword.get('passwordConfirm').hasError('required')">
                Password confirmation is required
            </mat-error>
            <mat-error *ngIf="ChangePassword.get('passwordConfirm').hasError('mustMatch')">
                Passwords must match
            </mat-error>
        </mat-form-field>
    </form>

    <div class="row">
        <button
            class="fuse-mat-button-large w-6/12 mt-6 ml-auto mr-auto"
            mat-flat-button
            (click)="OnChangePassword()"
            [disabled]="ChangePassword.invalid || isLoading"
            [color]="'primary'">
            <mat-spinner
                *ngIf="isLoading"
                [diameter]="24"
                class="mr-2">
            </mat-spinner>
            <span>{{ isLoading ? 'Saving...' : 'Save' }}</span>
        </button>
    </div>
</div>