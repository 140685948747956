import { Directive, HostListener, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AsYouType } from 'libphonenumber-js'

@Directive({
  selector: '[appPhoneNumMask]'
})
export class PhoneNumMaskDirective implements OnChanges, AfterViewInit {
  @Input('countryCode') countryCode: string;

  constructor(public ngControl: NgControl) { }

  // Apply masking when the model changes
  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event);
  }

  // Handle backspace key to reapply the mask
  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['countryCode'] && this.ngControl.value) {
      this.onInputChange(this.ngControl.value); // Reapply mask when countryCode changes
    }
  }

  ngAfterViewInit() {
    if (this.ngControl.value) {
      this.onInputChange(this.ngControl.value); // Reapply mask on view initialization
    }
  }

  onInputChange(event) {
    let newVal = event.replace(/\D/g, ''); // Remove non-numeric characters

    if (newVal.length > 0) {
      newVal = this.formatPhoneNumber(newVal); // Apply phone number formatting
    }

    this.ngControl.valueAccessor.writeValue(newVal); // Update the form control value
  }

  formatPhoneNumber(value: string): string {
    if (value.length <= 3) {
      return value; // Just return the digits if less than 3
    } else if (value.length <= 6) {
      return `(${value.slice(0, 3)}) ${value.slice(3)}`; // Format as (xxx) xxx
    } else {
      return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`; // Format as (xxx) xxx-xxxx
    }
  }


  private getFormatter(): AsYouType {
    let asYouType: AsYouType = null;

    switch (this.countryCode) {
      case 'us':
        asYouType = new AsYouType('US');
        break;
      case 'jp':
        asYouType = new AsYouType('JP');
        break;
      case 'ca':
        asYouType = new AsYouType('CA');
        break;
      case 'ph':
        asYouType = new AsYouType('PH');
        break;
      case 'mx':
        asYouType = new AsYouType('MX');
        break;
      case 'be':
        asYouType = new AsYouType('BE');
        break;
      case 'lu':
        asYouType = new AsYouType('LU');
        break;
      case 'de':
        asYouType = new AsYouType('DE');
        break;
      case 'br':
        asYouType = new AsYouType('BR');
        break;
      case 'pk':
        asYouType = new AsYouType('PK');
        break;
      case 'af':
        asYouType = new AsYouType('AF');
        break;
      case 'al':
        asYouType = new AsYouType('AL');
        break;
      case 'dz':
        asYouType = new AsYouType('DZ');
        break;
      case 'as':
        asYouType = new AsYouType('AS');
        break;
      case 'ad':
        asYouType = new AsYouType('AD');
        break;
      case 'ao':
        asYouType = new AsYouType('AO');
        break;
      case 'ai':
        asYouType = new AsYouType('AI');
        break;
      case 'ag':
        asYouType = new AsYouType('AG');
        break;
      case 'ar':
        asYouType = new AsYouType('AR');
        break;
      case 'am':
        asYouType = new AsYouType('AM');
        break;
      case 'aw':
        asYouType = new AsYouType('AW');
        break;
      case 'ac':
        asYouType = new AsYouType('AC');
        break;
      case 'au':
        asYouType = new AsYouType('AU');
        break;
      case 'at':
        asYouType = new AsYouType('AT');
        break;
      case 'az':
        asYouType = new AsYouType('AZ');
        break;
      case 'bs':
        asYouType = new AsYouType('BS');
        break;
      case 'bh':
        asYouType = new AsYouType('BH');
        break;
      case 'bd':
        asYouType = new AsYouType('BD');
        break;
      case 'bb':
        asYouType = new AsYouType('BB');
        break;
      case 'by':
        asYouType = new AsYouType('BY');
        break;
      case 'be':
        asYouType = new AsYouType('BE');
        break;
      case 'bz':
        asYouType = new AsYouType('BZ');
        break;
      case 'bj':
        asYouType = new AsYouType('BJ');
        break;
      case 'bm':
        asYouType = new AsYouType('BM');
        break;
      case 'bt':
        asYouType = new AsYouType('BT');
        break;
      case 'bo':
        asYouType = new AsYouType('BO');
        break;
      case 'ba':
        asYouType = new AsYouType('BA');
        break;
      case 'bw':
        asYouType = new AsYouType('BW');
        break;
      case 'io':
        asYouType = new AsYouType('IO');
        break;
      case 'vg':
        asYouType = new AsYouType('VG');
        break;
      case 'bn':
        asYouType = new AsYouType('BN');
        break;
      case 'bg':
        asYouType = new AsYouType('BG');
        break;
      case 'bf':
        asYouType = new AsYouType('BF');
        break;
      case 'bi':
        asYouType = new AsYouType('BI');
        break;
      case 'kh':
        asYouType = new AsYouType('KH');
        break;
      case 'cm':
        asYouType = new AsYouType('CM');
        break;
      case 'cv':
        asYouType = new AsYouType('CV');
        break;
      case 'bq':
        asYouType = new AsYouType('BQ');
        break;
      case 'ky':
        asYouType = new AsYouType('KY');
        break;
      case 'cf':
        asYouType = new AsYouType('CF');
        break;
      case 'td':
        asYouType = new AsYouType('TD');
        break;
      case 'cl':
        asYouType = new AsYouType('CL');
        break;
      case 'cn':
        asYouType = new AsYouType('CN');
        break;
      case 'co':
        asYouType = new AsYouType('CO');
        break;
      case 'km':
        asYouType = new AsYouType('KM');
        break;
      case 'cg':
        asYouType = new AsYouType('CG');
        break;
      case 'cd':
        asYouType = new AsYouType('CD');
        break;
      case 'ck':
        asYouType = new AsYouType('CK');
        break;
      case 'cr':
        asYouType = new AsYouType('CR');
        break;
      case 'ci':
        asYouType = new AsYouType('CI');
        break;
      case 'hr':
        asYouType = new AsYouType('HR');
        break;
      case 'cu':
        asYouType = new AsYouType('CU');
        break;
      case 'cw':
        asYouType = new AsYouType('CW');
        break;
      case 'cy':
        asYouType = new AsYouType('CY');
        break;
      case 'cz':
        asYouType = new AsYouType('CZ');
        break;
      case 'dk':
        asYouType = new AsYouType('DK');
        break;
      case 'dj':
        asYouType = new AsYouType('DJ');
        break;
    }

    return asYouType;
  }

}
