/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    BehaviorSubject,
    filter,
    map,
    Observable,
    of,
    switchMap,
    take,
    tap,
    throwError,
} from 'rxjs';
import {
    InventoryBrand,
    InventoryCategory,
    InventoryPagination,
    InventoryProduct,
    InventoryTag,
    InventoryVendor,
} from 'app/modules/admin/ecommerce/inventory/inventory.types';
import {environment} from  '../../../../../environments/environment';
@Injectable({
    providedIn: 'root',
})
export class InventoryService {
    // Private
    private _brands: BehaviorSubject<InventoryBrand[] | null> =
        new BehaviorSubject(null);
    private _categories: BehaviorSubject<InventoryCategory[] | null> =
        new BehaviorSubject(null);
    private _pagination: BehaviorSubject<InventoryPagination | null> =
        new BehaviorSubject(null);
    private _product: BehaviorSubject<InventoryProduct | null> =
        new BehaviorSubject(null);
    private _products: BehaviorSubject<InventoryProduct[] | null> =
        new BehaviorSubject(null);
    private _tags: BehaviorSubject<InventoryTag[] | null> = new BehaviorSubject(
        null
    );
    private _vendors: BehaviorSubject<InventoryVendor[] | null> =
        new BehaviorSubject(null);
    private _subGroups: BehaviorSubject<any[] | null> = new BehaviorSubject(
        null
    );
    private _dataForUpdate: BehaviorSubject<any | null> = new BehaviorSubject(
        null
    );

    apiUrl = environment.apiUrl
    extension = new BehaviorSubject(undefined);
    _GroupAlert = new BehaviorSubject(undefined);
    groupAlert = new BehaviorSubject<any>({});
    userAlert = new BehaviorSubject<any>({});
    _userAlert = new BehaviorSubject<any>({});
    _CloudPbx = new BehaviorSubject(undefined);

    dataForUpdate = new BehaviorSubject(undefined);

    Variable = new BehaviorSubject<any>({});
    UserVariable = new BehaviorSubject(undefined);
    userCloudPbx = new BehaviorSubject<any>({});

    usersCloudPbxFilterList = new BehaviorSubject<any>({});

    SubVariable = new BehaviorSubject(undefined);
    SubUserVariable = new BehaviorSubject(undefined);
    FilterGroupsVariable = new BehaviorSubject(undefined);
    GetSelectedGroupname = new BehaviorSubject(undefined);
    GroupLevel = new BehaviorSubject(undefined);

    groupsAndUser = new BehaviorSubject<boolean>(true);

    userCheck = new BehaviorSubject<boolean>(false);

    GroupLevel2 = new BehaviorSubject(undefined);
    GroupLevel4 = new BehaviorSubject(undefined);
    GroupLevel5 = new BehaviorSubject(undefined);
    GroupLevel3 = new BehaviorSubject(undefined);
    MainGroupName = new BehaviorSubject(undefined);
    UpdateForUser: any;
    UpdateForGroup: any;
    TreeSlectedId: any;
    DefaultId: any;
    private messageSource = new BehaviorSubject(undefined);
    currentMessage = this.messageSource.asObservable();

    private groupAndUserUri =
        `${this.apiUrl}groups/getGroup`;
    private addExtensionURL =
        'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/addExtension';

    private getExtensionURL =
        'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/getExtensions';

    private deleteExtensionURL =
        ' https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/deleteExtensions';
    CallLogsVariable: any;
    AllTrackingNum: any;
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for brands
     */
    get brands$(): Observable<InventoryBrand[]> {
        return this._brands.asObservable();
    }
    selectSubgroup(subgroup: any): void {
        // Assume this method is triggered when a subgroup is selected
        this.GetSelectedGroupname.next(subgroup.name);
    
        // Update the breadcrumb with the selected subgroup
        this.updateBreadcrumb(subgroup);
    }
    
    updateBreadcrumb(subgroup: any): void {
        // Logic to update the breadcrumb based on the selected subgroup
        const breadcrumb = this.generateBreadcrumb(subgroup);
        this.messageSource.next(breadcrumb); // Or whichever subject you're using for breadcrumbs
    }
    
    generateBreadcrumb(subgroup: any): string {
        // Create a breadcrumb string or array based on subgroup data
        return `Home > ${subgroup.parentName} > ${subgroup.name}`;
    }
    
    /**
     * Getter for categories
     */
    get categories$(): Observable<InventoryCategory[]> {
        return this._categories.asObservable();
    }

    /**
     * Getter for pagination
     */
    get pagination$(): Observable<InventoryPagination> {
        return this._pagination.asObservable();
    }

    /**
     * Getter for product
     */
    get product$(): Observable<InventoryProduct> {
        return this._product.asObservable();
    }

    /**
     * Getter for products
     */
    get products$(): Observable<InventoryProduct[]> {
        return this._products.asObservable();
    }

    get subGroups$(): Observable<any> {
        return this._subGroups.asObservable();
    }

    /**
     * Getter for tags
     */
    get tags$(): Observable<InventoryTag[]> {
        return this._tags.asObservable();
    }

    /**
     * Getter for vendors
     */
    get vendors$(): Observable<InventoryVendor[]> {
        return this._vendors.asObservable();
    }

    get dataForUpdate$(): Observable<any> {
        return this._dataForUpdate.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get brands
     */
    getBrands(): Observable<InventoryBrand[]> {
        return this._httpClient
            .get<InventoryBrand[]>('api/apps/ecommerce/inventory/brands')
            .pipe(
                tap((brands) => {
                    this._brands.next(brands);
                })
            );
    }

    /**
     * Get categories
     */
    getCategories(): Observable<InventoryCategory[]> {
        return this._httpClient
            .get<InventoryCategory[]>('api/apps/ecommerce/inventory/categories')
            .pipe(
                tap((categories) => {
                    this._categories.next(categories);
                })
            );
    }

    /**
     * Get products
     *
     *
     * @param page
     * @param size
     * @param sort
     * @param order
     * @param search
     */
    getProducts(
        page: number = 0,
        size: number = 10,
        sort: string = 'name',
        order: 'asc' | 'desc' | '' = 'asc',
        search: string = ''
    ): Observable<{
        pagination: InventoryPagination;
        products: InventoryProduct[];
    }> {
        return this._httpClient
            .get<{
                pagination: InventoryPagination;
                products: InventoryProduct[];
            }>('api/apps/ecommerce/inventory/products', {
                params: {
                    page: '' + page,
                    size: '' + size,
                    sort,
                    order,
                    search,
                },
            })
            .pipe(
                tap((response) => {
                    this._pagination.next(response.pagination);
                    this._products.next(response.products);
                })
            );
    }

    /**
     * Get product by id
     */
    getProductById(id: string): Observable<InventoryProduct> {
        return this._products.pipe(
            take(1),
            map((products) => {
                // Find the product
                const product = products.find((item) => item.id === id) || null;

                // Update the product
                this._product.next(product);

                // Return the product
                return product;
            }),
            switchMap((product) => {
                if (!product) {
                    return throwError(
                        'Could not found product with id of ' + id + '!'
                    );
                }

                return of(product);
            })
        );
    }

    /**
     * Create product
     */
    createProduct(): Observable<InventoryProduct> {
        return this.products$.pipe(
            take(1),
            switchMap((products) =>
                this._httpClient
                    .post<InventoryProduct>(
                        'api/apps/ecommerce/inventory/product',
                        {}
                    )
                    .pipe(
                        map((newProduct) => {
                            // Update the products with the new product
                            this._products.next([newProduct, ...products]);

                            // Return the new product
                            return newProduct;
                        })
                    )
            )
        );
    }

    /**
     * Update product
     *
     * @param id
     * @param product
     */
    updateProduct(
        id: string,
        product: InventoryProduct
    ): Observable<InventoryProduct> {
        return this.products$.pipe(
            take(1),
            switchMap((products) =>
                this._httpClient
                    .patch<InventoryProduct>(
                        'api/apps/ecommerce/inventory/product',
                        {
                            id,
                            product,
                        }
                    )
                    .pipe(
                        map((updatedProduct) => {
                            // Find the index of the updated product
                            const index = products.findIndex(
                                (item) => item.id === id
                            );

                            // Update the product
                            products[index] = updatedProduct;

                            // Update the products
                            this._products.next(products);

                            // Return the updated product
                            return updatedProduct;
                        }),
                        switchMap((updatedProduct) =>
                            this.product$.pipe(
                                take(1),
                                filter((item) => item && item.id === id),
                                tap(() => {
                                    // Update the product if it's selected
                                    this._product.next(updatedProduct);

                                    // Return the updated product
                                    return updatedProduct;
                                })
                            )
                        )
                    )
            )
        );
    }

    /**
     * Delete the product
     *
     * @param id
     */
    deleteProduct(id: string): Observable<boolean> {
        return this.products$.pipe(
            take(1),
            switchMap((products) =>
                this._httpClient
                    .delete('api/apps/ecommerce/inventory/product', {
                        params: { id },
                    })
                    .pipe(
                        map((isDeleted: boolean) => {
                            // Find the index of the deleted product
                            const index = products.findIndex(
                                (item) => item.id === id
                            );

                            // Delete the product
                            products.splice(index, 1);

                            // Update the products
                            this._products.next(products);

                            // Return the deleted status
                            return isDeleted;
                        })
                    )
            )
        );
    }

    /**
     * Get tags
     */
    getTags(): Observable<InventoryTag[]> {
        return this._httpClient
            .get<InventoryTag[]>('api/apps/ecommerce/inventory/tags')
            .pipe(
                tap((tags) => {
                    this._tags.next(tags);
                })
            );
    }

    /**
     * Create tag
     *
     * @param tag
     */
    createTag(tag: InventoryTag): Observable<InventoryTag> {
        return this.tags$.pipe(
            take(1),
            switchMap((tags) =>
                this._httpClient
                    .post<InventoryTag>('api/apps/ecommerce/inventory/tag', {
                        tag,
                    })
                    .pipe(
                        map((newTag) => {
                            // Update the tags with the new tag
                            this._tags.next([...tags, newTag]);

                            // Return new tag from observable
                            return newTag;
                        })
                    )
            )
        );
    }

    /**
     * Update the tag
     *
     * @param id
     * @param tag
     */
    updateTag(id: string, tag: InventoryTag): Observable<InventoryTag> {
        return this.tags$.pipe(
            take(1),
            switchMap((tags) =>
                this._httpClient
                    .patch<InventoryTag>('api/apps/ecommerce/inventory/tag', {
                        id,
                        tag,
                    })
                    .pipe(
                        map((updatedTag) => {
                            // Find the index of the updated tag
                            const index = tags.findIndex(
                                (item) => item.id === id
                            );

                            // Update the tag
                            tags[index] = updatedTag;

                            // Update the tags
                            this._tags.next(tags);

                            // Return the updated tag
                            return updatedTag;
                        })
                    )
            )
        );
    }

    /**
     * Delete the tag
     *
     * @param id
     */
    deleteTag(id: string): Observable<boolean> {
        return this.tags$.pipe(
            take(1),
            switchMap((tags) =>
                this._httpClient
                    .delete('api/apps/ecommerce/inventory/tag', {
                        params: { id },
                    })
                    .pipe(
                        map((isDeleted: boolean) => {
                            // Find the index of the deleted tag
                            const index = tags.findIndex(
                                (item) => item.id === id
                            );

                            // Delete the tag
                            tags.splice(index, 1);

                            // Update the tags
                            this._tags.next(tags);

                            // Return the deleted status
                            return isDeleted;
                        }),
                        filter((isDeleted) => isDeleted),
                        switchMap((isDeleted) =>
                            this.products$.pipe(
                                take(1),
                                map((products) => {
                                    // Iterate through the contacts
                                    products.forEach((product) => {
                                        const tagIndex = product.tags.findIndex(
                                            (tag) => tag === id
                                        );

                                        // If the contact has the tag, remove it
                                        if (tagIndex > -1) {
                                            product.tags.splice(tagIndex, 1);
                                        }
                                    });

                                    // Return the deleted status
                                    return isDeleted;
                                })
                            )
                        )
                    )
            )
        );
    }

    /**
     * Get vendors
     */
    getVendors(): Observable<InventoryVendor[]> {
        return this._httpClient
            .get<InventoryVendor[]>('api/apps/ecommerce/inventory/vendors')
            .pipe(
                tap((vendors) => {
                    this._vendors.next(vendors);
                })
            );
    }

    // getGroupAndUser(): Observable<any>{
    //     const token = localStorage.getItem('token');
    //     const headers_object = new HttpHeaders({'token': token});
    //     const staticBodyObj = {
    //       "name": "BMW-1"
    //   }

    //     const httpOptions = {
    //         headers: headers_object
    //       };

    //     return this._httpClient.post(this.groupAndUserUri,staticBodyObj,httpOptions);
    //   }
 
    changeMessage(message: string) {
        this.messageSource.next(message);
    }
    private TableSource = new BehaviorSubject(undefined);
    currentTableSource = this.TableSource.asObservable();
    TableMessage(message: any) {
        this.TableSource.next(message);
    }
    getGroupAndUser(
        groupId: any,
        page?: any,
        rows? :any,
        filter_items?: any[]
    ): Observable<{ subgoroups: [] }> {
        const token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);


        return this._httpClient.post<{ subgoroups: any }>(
            this.groupAndUserUri,
            { groupId, page, rows, filter_items },
            { headers }
        );
    } //

    ///cloud PBX
    getCloudPBXuser(id: any, filter_items: any = []): Observable<{}> {
        const tableName = 'cloudPbx';
        const token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/filter',
            { tableName, id, filter_items },
            { headers }
        );

    } //

    getCloudUserList(ouid: any, isCloudUser?: any): Observable<{}>{
            const token = localStorage.getItem('token');
            const headers = new HttpHeaders().set('token', token);
            return this._httpClient.post<{ subgoroups: any }>(
                'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/getUsersCheck',
                { isCpbx: isCloudUser, groupId: ouid },
                { headers }
            );
    }



    getAllTreeGroups(): Observable<{}> {
        const token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ Authorization: `Bearer ${token}` });

        const httpOptions = {
            headers: headers_object,
        };

        return this._httpClient.get<{}>(
            `${this.apiUrl}groups/getTree`,
            httpOptions
        );
    }
    //Post Audio
    checkAvailability(number) {
        const token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ token: token });

        const httpOptions = {
            headers: headers_object,
        };
        return this._httpClient.post(
            'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/checkExtension',
            {
                extension: number,
            },
            httpOptions
        );
    }

    makePagination(dataArray) {
        let paginationValues: any;

        const search = 'search';
        const sort: any = 'groupName';
        const order = 'asc';
        const page = 0;
        const size = 10;

        // Clone the products
        let products: any[] | null = dataArray;

        // Sort the products
        if (
            sort === 'sku' ||
            sort === 'groupName' ||
            sort === 'active' ||
            sort === 'externalId' ||
            sort === 'industry'
        ) {
            products.sort((a, b) => {
                const fieldA = a[sort].toString().toUpperCase();
                const fieldB = b[sort].toString().toUpperCase();
                return order === 'asc'
                    ? fieldA.localeCompare(fieldB)
                    : fieldB.localeCompare(fieldA);
            });
        } else {
            products.sort((a, b) =>
                order === 'asc' ? a[sort] - b[sort] : b[sort] - a[sort]
            );
        }

        // If search exists...
        if (search) {
            // Filter the products
            products = products.filter(
                (contact) =>
                    contact.groupName &&
                    contact.groupName
                        .toLowerCase()
                        .includes(search.toLowerCase())
            );
        }

        // Paginate - Start
        const productsLength = products.length;

        // Calculate pagination details
        const begin = page * size;
        const end = Math.min(size * (page + 1), productsLength);
        const lastPage = Math.max(Math.ceil(productsLength / size), 1);

        // Prepare the pagination object
        let pagination = {};

        // If the requested page number is bigger than
        // the last possible page number, return null for
        // products but also send the last possible page so
        // the app can navigate to there
        if (page > lastPage) {
            products = null;
            pagination = {
                lastPage,
            };
        } else {
            // Paginate the results by size
            products = products.slice(begin, end);

            // Prepare the pagination mock-api
            pagination = {
                length: productsLength,
                size: size,
                page: page,
                lastPage: lastPage,
                startIndex: begin,
                endIndex: end - 1,
            };
        }
    }

    createUser(userDetails: any, extensionDetails: any, groupId: any) {
        let token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);



        return this._httpClient.post(
         `${this.apiUrl}groups/createUser`,
            { userDetails, extensionDetails, groupId },

            { headers }
        );
    }
    addExtension(number: number) {
        let token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            this.addExtensionURL,
            { extensionNumber: number },
            { headers }
        );
    }

    getExtension() {
        let token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.get(this.getExtensionURL, { headers });
    }

    deleteExtension(id, number) {
        let token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            this.deleteExtensionURL,
            { id: id, number: number },
            { headers }
        );
    }

    createGroup(groupDetails: any, parentId: any) {
        let token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        return this._httpClient.post(
            `${this.apiUrl}groups/createGroup`,
            { groupDetails, parentId },
            { headers }
        );
    }
    UpdateGroup(groupDetails: any) {
        let token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    
        return this._httpClient.put(
            `${this.apiUrl}groups/updateGroup`,
            groupDetails,
            { headers }
        );
    }
    

    UpdateUser(userDetails: any) {
        let token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        return this._httpClient.put(
           `${this.apiUrl}groups/updateUser`,
            userDetails,
            { headers }
        );
    }
 

    DeleteUser(ouid: string): Observable<any> {
        let token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    
        // Pass groupId as query parameter in the URL
        return this._httpClient.delete(
            `${this.apiUrl}groups/deleteUser?ouid=${ouid}`,
            { headers }
        );
    }


    deleteGroup(groupId: string): Observable<any> {
        let token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    
        // Pass groupId as query parameter in the URL
        return this._httpClient.delete(
            `${this.apiUrl}groups/deleteGroup?groupId=${groupId}`,
            { headers }
        );
    }
            
    GetParentName(ouid: number) {
        let token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/getParentName',
            { ouid },
            { headers }
        );
    }

    GetFilterGroupCall(filter_items: any = []) {
        let tableName = 'groups';
        let token = localStorage.getItem('token');
        let iud = localStorage.getItem('TreeID');
        let DID = localStorage.getItem('Group');
        let id = iud;
        // if (iud === null) {
        //     id = Number(DID);
        // } else {
        //     id = iud;
        // }
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/filter',
            { tableName, id, filter_items },
            { headers }
        );
    }
    GetPermissions(ouid: any) {
        let token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/permissions',
            { ouid },
            { headers }
        );
    }
    UpdatePermissions(data: any = []) {
        let token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/changePermissions',
            { data },
            { headers }
        );
    }
    GetFilterUserCall(filter_items: any = []) {
        let tableName = 'users';
        let token = localStorage.getItem('token');
        let iud = localStorage.getItem('TreeID');
        let DID = localStorage.getItem('Group');
        let id = iud;
        // if (iud === null) {
        //     id = Number(DID);
        // } else {
        //     id = iud;
        // }
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/filter',
            { tableName, id, filter_items },
            { headers }
        );
    }
    getGroupById(id) {
        let token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ token: token });
        const staticBodyObj = {
            name: 'BMW-1',
        };

        const httpOptions = {
            headers: headers_object,
        };
        return this._httpClient.get(
            'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/creategroup/' +
                id,
            httpOptions
        );
    }
    updateUserPassword(email: any, password: any) {
        let token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        return this._httpClient.put(
            `${this.apiUrl}groups/changePassword`,
            { email, password },
            { headers }
        );
    }
    /**
     * Update the avatar of the given contact
     *
     * @param id
     * @param avatar
     */
    /*uploadAvatar(id: string, avatar: File): Observable<Contact>
    {
        return this.contacts$.pipe(
            take(1),
            switchMap(contacts => this._httpClient.post<Contact>('api/apps/contacts/avatar', {
                id,
                avatar
            }, {
                headers: {
                    'Content-Type': avatar.type
                }
            }).pipe(
                map((updatedContact) => {

                    // Find the index of the updated contact
                    const index = contacts.findIndex(item => item.id === id);

                    // Update the contact
                    contacts[index] = updatedContact;

                    // Update the contacts
                    this._contacts.next(contacts);

                    // Return the updated contact
                    return updatedContact;
                }),
                switchMap(updatedContact => this.contact$.pipe(
                    take(1),
                    filter(item => item && item.id === id),
                    tap(() => {

                        // Update the contact if it's selected
                        this._contact.next(updatedContact);

                        // Return the updated contact
                        return updatedContact;
                    })
                ))
            ))
        );
    }*/
}
