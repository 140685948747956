import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OutsideDirective } from 'app/outside.directive';
import { GroupsTreeComponent } from './groups-tree/groups-tree.component';
import { CdkTableModule } from '@angular/cdk/table';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { HeaderNbuttonComponent } from './Header&Button/header-nbutton/header-nbutton.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { SharedFilterComponent } from './shared-filter/shared-filter.component';
import { FilterItemsComponent } from './shared-filter/filter-items/filter-items.component';
import { DateFilterComponent } from './shared-filter/date-filter/date-filter.component';
import { AutoWidthDirective } from './shared-filter/auto-width.directive';
import {MatMenuModule} from '@angular/material/menu';
import { AlertToastComponent } from './alert-toast/alert-toast.component';
import { PhoneMaskDirective } from './phone-mask.directive';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedTableComponent } from './shared-table/shared-table.component';
import { BreadCrumbsComponent } from './breadCrumbs/bread-crumbs/bread-crumbs.component';

@NgModule({
    declarations: [
        OutsideDirective,
        GroupsTreeComponent,
        BreadCrumbsComponent,
    
        HeaderNbuttonComponent,
        SharedFilterComponent,
        FilterItemsComponent,
        DateFilterComponent,
        AutoWidthDirective,
        AlertToastComponent,
        PhoneMaskDirective,
        ChangePasswordComponent,
        SharedTableComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CdkTableModule,
        ScrollingModule,
        MatIconModule,
        MatCheckboxModule,
        MatButtonModule,
        MatInputModule,
        MatSelectModule,
        MatMenuModule,
        MatDialogModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        OutsideDirective,
        BreadCrumbsComponent,
        HeaderNbuttonComponent,
        SharedFilterComponent,
        SharedTableComponent,
        PhoneMaskDirective
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
