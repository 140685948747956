<div
    class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-2 px-5 md:px-8 border-b"
>
    <div class="text-2xl font-semibold tracking-tight">
        {{ headingTitle }}
    </div>
    <!-- Actions -->
    <div class="flex shrink-0 items-center mt-6 sm:mt-0">
        <button
        *ngIf="showCreateButton"
            class="w-32"
            mat-flat-button
            [color]="'primary'"
            (click)="ButtonClick()"
        >
            <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
            <span class="ml-1">Create</span>
        </button>
    </div>
</div>
