import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TreeItemNode } from './models/TreeItemNode';

@Injectable({
	providedIn: 'root',
})
export class GroupsDatabaseService {
	TREE_DATA = [];
	dataChange = new BehaviorSubject<any>([]);
    GroupsArray = new BehaviorSubject<any>([]);
    TodayFilter = new BehaviorSubject<any>([]);
    selectedNodes = new BehaviorSubject<any>([]);

	constructor() {}

	initGroups(group_list) {
		if (group_list && group_list.length > 0) {
			this.TREE_DATA = group_list;
		} else {
			this.TREE_DATA = JSON.parse(localStorage.getItem('groups')) || [];
		}
		const data = this.buildFileTree(this.TREE_DATA, '0');
		this.dataChange.next(data);
	}

	buildFileTree(obj: any[], level: string): TreeItemNode[] {
		const groupedByParent = obj.reduce((acc, curr) => {
			const code = curr.code || '';
			const parentCode = code.substr(0, code.lastIndexOf('.')) || '0';
			if (!acc[parentCode]) acc[parentCode] = [];
			acc[parentCode].push(curr);
			return acc;
		}, {});

		return this.constructTree(groupedByParent, level);
	}

	constructTree(groupedByParent, level: string): TreeItemNode[] {
		const nodes = groupedByParent[level] || [];
		return nodes.map((o) => {
			const node = new TreeItemNode();
			node.item = o.text;
			node.code = o.code;
			node.id = o.id;
			node.isDisabled = !!o.isDisabled;

			const children = this.constructTree(groupedByParent, o.code);
			if (children.length > 0) node.children = children;

			return node;
		});
	}
}
