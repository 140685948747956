import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-header-nbutton',
    templateUrl: './header-nbutton.component.html',
    styleUrls: ['./header-nbutton.component.scss'],
})
export class HeaderNbuttonComponent implements OnInit {
    @Input() showCreateButton: boolean = false;
    @Input() headingTitle: string;
    @Output() OnButtonClick: EventEmitter<any> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}
    ButtonClick() {
        this.OnButtonClick.emit();
    }
}
